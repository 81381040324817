import React, { useState } from 'react';
import { Link, Text, useMediaQuery, useColorModeValue } from '@chakra-ui/react';
import {
  Card,
  CardContent,
  CardPhoto,
  CardFooter,
  CardFooterTitle,
  CardFooterTimestamp,
} from 'components';
import ProjectActions from './project-actions';
import userProjects from '../../../hooks/use-projects';

const Project = ({
  coverPhoto,
  title,
  updatedAt,
  nickname,
  projectId,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { allProjects, postRemotionProject, trashProject } = userProjects();

  const handleMouseEnter = () => setIsActive(true);

  const handleMouseLeave = () => {
    if (isOpen) return;
    setIsActive(false);
  };

  const handleCopy = () => {
    setIsActive(false);
    setIsOpen(false);
    postRemotionProject(allProjects, projectId);
  };

  const handleDelete = () => {
    setIsActive(false);
    setIsOpen(false);
    trashProject(allProjects, projectId);
  };

  const handleExport = () => {
    setIsActive(false);
    setIsOpen(false);
  };

  const handleOnProjectClick = () => {
    window.location.replace(`https://app.clippy.io/canvas/${projectId}`);
  };

  //xs
  const [isLargerThan0] = useMediaQuery('(min-width: 0px)');
  //sm
  const [isLargerThan576] = useMediaQuery('(min-width: 576px)');
  //md
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  //lg
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)');
  const fontSize = React.useMemo(() => {
    if (isLargerThan0 && !isLargerThan576) {
      return 'sm';
    } else if (isLargerThan576 && !isLargerThan768) {
      return 'sm';
    } else if (isLargerThan768 && !isLargerThan992) {
      return 'md';
    } else if (isLargerThan992) {
      return 'lg';
    }
  }, [isLargerThan0, isLargerThan576, isLargerThan768, isLargerThan992]);

  return (
    <Card
      cursor={'pointer'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      <CardContent>
        <CardPhoto
          src={coverPhoto}
          title={title}
          onClick={handleOnProjectClick}
        />
        <ProjectActions
          isActive={isActive}
          isOpen={isOpen}
          setIsActive={setIsActive}
          setIsOpen={setIsOpen}
          title={title}
          nickname={nickname}
          onCopy={handleCopy}
          onExport={handleExport}
          onRemove={handleDelete}
        />
      </CardContent>
      <CardFooter>
        <CardFooterTitle>
          <Link
            href={`https://app.clippy.io/canvas/${projectId}`}
            size={['xs', 'md']}
            _hover={{
              // show underline link on hover
              textDecoration: 'underline',
            }}
          >
            <Text
              as={'span'}
              mr={'0.5rem'}
              fontSize={fontSize}
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {title}
            </Text>
          </Link>
        </CardFooterTitle>
        <CardFooterTimestamp>{updatedAt}</CardFooterTimestamp>
      </CardFooter>
    </Card>
  );
};

export default Project;
