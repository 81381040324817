import get from 'lodash/get';
import { uniqueId } from 'lodash';

export const generateClipTemplate = (template, projectId) => {
  const width = get(template, 'width', 0);
  const height = get(template, 'height', 0);
  const templateDuration = get(template, 'duration', 0);
  const s3Url = get(template, 's3Url', '');
  const s3Key = get(template, 's3Key', '');
  const templateId = uniqueId();

  // calculate the canvas image ration and canvas width dimentions
  const x = width / 2;
  const y = height / 2;

  const tempid = {
    id: templateId,
    duration: Number(templateDuration),
    scrubberRow: 1,
    mute: false,
    visible: true,
    locked: false,
    items: [
      {
        reducerType: 'videos',
        s3Url: s3Url,
        fileName: 'big_buck_bunny_720p_1mb.mp4',
        src: s3Url,
        type: 'video/mp4',
        duration: Number(templateDuration),
        s3Key: s3Key,
        name: `${templateId}-video`,
        width: Number(width),
        id: uniqueId(),
        thumbUrl:
          'https://eyecastvideoeditorfiles.s3.ap-southeast-2.amazonaws.com/public/irfan%40trolio.com/6472d56e805c3bffc3cded33/videoframes/VD5xIo7tmD/big_buck_bunny_720p_1mb-cover.jpg',
        projectId: projectId,
        uniqueId: uniqueId(),
        height: Number(height),
        droptype: 'videos',
        start: 0,
        end: Number(templateDuration) * 1000,
        frameStartAt: 0,
        frameEndAt: Number(templateDuration) * 1000,
        locked: false,
        visible: true,
        mute: false,
        timestamp: 1697861610,
        isAudio: false,
        isVideo: true,
        transformation: {
          width: Number(width),
          height: Number(height),
          x: x,
          y: y,
          rotate: 0,
          scale: [1, 1],
          zIndex: 1,
          toCenter: true,
          effect: 'none',
          animation: 'NONE',
          stroke: null,
          strokeWidth: 0,
          opacity: 1,
          shadowEnabled: false,
          blurEnabled: false,
          shadowColor: null,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowBlur: 0,
          colorCorrection: {
            reset: false,
          },
          blurRadius: 0,
        },
        frames: [null],
        isAudioDetached: false,
        scrubberRow: 1,
      },
    ],
  };
  return tempid;
};
