import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Center, Text, useCheckbox } from '@chakra-ui/react';

const CheckboxCard = ({ children, src, render, ...props }) => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Center
        {...checkbox}
        height={'6.5rem'}
        position={'relative'}
        bgColor={'gray.600'}
        borderRadius={'md'}
        cursor="pointer"
        _checked={{
          borderStyle: 'solid',
          borderWidth: '3px',
          borderColor: 'blue.500',
        }}
      >
        {input?.checked && (
          <Box position={'absolute'} top={0} right={0} p={2} color={'blue.500'}>
            <FontAwesomeIcon icon={['fas', 'check-circle']} size={'lg'} />
          </Box>
        )}
        <Box p={2} textAlign={'center'}>
          {render ? render(src) : <Text fontSize={'3xl'}>{src}</Text>}
          <Text fontSize={'sm'} color={'white'} fontWeight={'700'}>
            {children}
          </Text>
        </Box>
      </Center>
    </Box>
  );
};

export default CheckboxCard;
