import React from 'react';

import { Slider, SliderItem, Button } from 'components';

const tags = [
  { _id: 1, title: 'Trending' },
  { _id: 2, title: 'Packaging' },
  { _id: 3, title: 'Commercials' },
  { _id: 4, title: 'Logo Strings' },
  { _id: 5, title: 'Perfumes' },
  { _id: 6, title: 'Electronics' },
  { _id: 7, title: 'Shoes' },
  { _id: 8, title: 'Nature' },
  { _id: 9, title: 'Watches' },
  { _id: 10, title: 'Packaging' },
  { _id: 11, title: 'Commercials' },
  { _id: 12, title: 'Logo Strings' },
  { _id: 13, title: 'Trending' },
  { _id: 14, title: 'Packaging' },
  { _id: 15, title: 'Commercials' },
  { _id: 16, title: 'Logo Strings' },
];

const TemplateTags = ({ onClick }) => {
  return (
    <Slider>
      {tags.map(({ _id, title }) => (
        <SliderItem key={_id} minWidth={'inherit'}>
          <Button
            variant={'outline'}
            colorScheme={'blue'}
            label={title}
            zIndex={1}
            _hover={{
              bg: 'blue.600',
              color: '#1A1C23',
            }}
            onClick={() => onClick(title)}
          />
        </SliderItem>
      ))}
    </Slider>
  );
};

export default TemplateTags;
