import { useMutation } from 'react-query';

import constants from '../constants';
import useApi from './use-api';

const useSaveAuthUser = () => {
  const api = useApi();

  return useMutation(({ id, data }) =>
    api
      .put(`/${constants.endpoints.auth}/${encodeURIComponent(id)}`, data)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        if (err.response) {
          throw new Error(JSON.stringify(err.response.data));
        }

        throw new Error(JSON.stringify({ type: '', message: err.message }));
      })
  );
};

export default useSaveAuthUser;
