import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useOnclickOutside from 'react-cool-onclickoutside';

import { UserButton, UserMenu } from 'components';

const HeaderUserMenu = () => {
  const { user } = useAuth0();
  const { picture } = user;

  const [baseElement, setBaseElement] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const ref = useOnclickOutside(
    () => {
      setIsOpen(false);
    },
    { disabled: !isOpen }
  );

  const handleClick = () => setIsOpen((prev) => !prev);

  return (
    <React.Fragment>
      <UserButton
        ref={setBaseElement}
        size={'sm'}
        picture={picture}
        onClick={handleClick}
      />
      <UserMenu ref={ref} baseElement={baseElement} isOpen={isOpen} />
    </React.Fragment>
  );
};

export default HeaderUserMenu;
