import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Box, Grid, Flex, Image, Link } from '@chakra-ui/react';

import logo from 'assets/images/clippy-logo.svg';

import { Container } from '../shared';

const Footer = () => {
  return (
    <Box backgroundColor="#000">
      <Container maxW="container.xl" centerContent>
        <Flex
          py={[8, null, null, 20, 20]}
          direction="column"
          alignItems="center"
          sx={{
            '>*:not(:last-child)': {
              mb: 8,
            },
          }}
        >
          <Image src={logo} alt={'Eyecase'} width="8rem" />
          <Flex minWidth="20rem" justifyContent="space-between">
            <FontAwesomeIcon icon={['fab', 'twitter']} size="2x" />
            <FontAwesomeIcon icon={['fab', 'youtube']} size="2x" />
            <FontAwesomeIcon icon={['fab', 'instagram']} size="2x" />
            <FontAwesomeIcon icon={['fab', 'facebook']} size="2x" />
            <FontAwesomeIcon icon={['fab', 'linkedin']} size="2x" />
          </Flex>
          <Flex direction="column" alignItems="center">
            <Grid gap="2" autoFlow="column" mb="2">
              <Link href="#">Privacy Policy</Link>
              <Box as="span" fontWeight={'700'}>
                &#183;
              </Box>
              <Link href="#">Terms & Conditions</Link>
            </Grid>
            <Box>&copy; Clippy. All rights reserved</Box>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
