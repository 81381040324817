import React from 'react';
import { SimpleGrid, useCheckboxGroup } from '@chakra-ui/react';

import { useOnboarding } from '../provider';
import CheckboxCard from './checkbox-card';

const options = [
  {
    value: 'The company I work for',
    src: '💼',
  },
  {
    value: 'My business',
    src: '🗄',
  },
  {
    value: 'Non-profit or Charity',
    src: '🎗',
  },
  {
    value: 'School project',
    src: '📚',
  },
  {
    value: 'Fun',
    src: '🎉',
  },
  {
    value: 'Other',
    src: '❓',
  },
];

const StepThree = () => {
  const onboarding = useOnboarding();

  const { getCheckboxProps } = useCheckboxGroup({
    name: 'occupation',
    defaultValue: onboarding?.state?.occupation,
    onChange: (val) =>
      onboarding.dispatch({ type: 'update', data: { occupation: val } }),
  });

  return (
    <SimpleGrid minChildWidth={'9rem'} spacing={5}>
      {options.map(({ src, value }) => {
        const checkbox = getCheckboxProps({ value });

        return (
          <CheckboxCard key={value} {...checkbox} src={src}>
            {value}
          </CheckboxCard>
        );
      })}
    </SimpleGrid>
  );
};

export default React.memo(StepThree);
