import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';

const usePexelsApi = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState('Nature');
  const [vidData, setVidData] = React.useState([]);

  /** Axios client */

  const { isLoading } = useQuery(
    {
      queryKey: ['FETCH_PEXELS_VIDEO_TEMPLATES', searchTerm],
      queryFn: async () => {
        const viddata = axios
          .get(
            `https://api.pexels.com/videos/search?query=${searchTerm}&szie=medium&per_page=15`,
            {
              headers: {
                Authorization:
                  'M8qVc0OkBJ7vhDhWXKKpr9gjThBNEzXuOPvOCUy0VooBVRTSH0YMwM8D',
              },
            }
          )
          .then(({ data }) => {
            const result = data.videos.map((vid) => {
              return {
                id: vid.id,
                width: vid.width,
                height: vid.height,
                duration: vid.duration,
                image: vid.image,
                user: vid.user,
                pexelLink: vid.url,
                video_files: vid.video_files,
              };
            });
            return result;
          });
        return viddata;
      },
      onSuccess: (data) => {
        setVidData(data);
      },
    },
    { refetchOnReconnect: false, refetchOnWindowFocus: false }
  );

  return {
    data: vidData,
    searchTerm,
    setSearchTerm,
    currentPage,
    setCurrentPage,
    setPageLimit,
    pageLimit,
    isLoading,
  };
};

export default usePexelsApi;
