import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import useEditable from 'hooks/use-editable';
import { Button } from 'components';
import { Content, HeaderTitle } from 'layouts/shared';

const EditableForm = ({ title, text, children, ...props }) => {
  const { isEditing, setIsEditing } = useEditable();

  return (
    <Content
      title={title}
      text={text}
      renderContent={({ title, text }) => {
        return isEditing ? (
          <Box width={'100%'}>
            {React.cloneElement(children, { isEditing, setIsEditing })}
          </Box>
        ) : (
          <Box pr={4} color={'gray.500'}>
            <HeaderTitle as={'h3'} pb={4} size={'sm'} fontWeight={700}>
              {title}
            </HeaderTitle>
            <Text pb={[4, 0]}>{text}</Text>
          </Box>
        );
      }}
    >
      {!isEditing && (
        <Button
          label={'Update'}
          colorScheme={'pink'}
          size="md"
          onClick={setIsEditing.toggle}
        />
      )}
    </Content>
  );
};

export default EditableForm;
