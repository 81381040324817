import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box, Button, Text, Image } from '@chakra-ui/react';

import { Container, Title, GradientText, ComingSoon } from '../shared';

const styleProps = {
  left: {
    content: {
      pr: [0, null, null, 6],
      order: [2, null, null, 1],
    },
    image: {
      order: [1, null, null, 2],
      pb: [6, null, null, 0],
    },
  },
  right: {
    content: {
      order: [1, null, null, 2],
    },
    image: {
      order: [2, null, null, 1],
      pr: [0, null, null, 6],
      pt: [6, null, null, 0],
    },
  },
};

const Feature = ({ data, direction }) => {
  const { title, description, imageUrl, isComingSoon } = data;

  const styles = styleProps[direction];
  const borderColor = direction === 'left' ? '#1A1C23' : '#535665';

  return (
    <Container maxW="container.xl" centerContent>
      <Box
        mb={12}
        p={[6, null, null, 12, 12]}
        border={`2px solid ${borderColor}`}
        backgroundColor="#262730"
        borderRadius={'3xl'}
      >
        {isComingSoon && <ComingSoon />}
        <Flex direction={['column', null, null, 'row']} align="center">
          <Box {...styles.content}>
            <Title mb={6} fontSize={['2xl', '2xl', null, '5xl']}>
              <Heading title={title} />
            </Title>
            <Text mb={6}>{description}</Text>
            <Link to="/dashboard">
              <Button colorScheme="pink" size="lg">
                Try Clippy for free
              </Button>
            </Link>
          </Box>

          <Box {...styles.image} maxWidth={[null, null, null, '34rem']}>
            <Image
              src={imageUrl}
              loading="lazy"
              alt="Clippy video editor preview"
            />
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

const Heading = ({ title }) => {
  const words = title.split(' ');
  const firstTwoWords = words.splice(0, 2).join(' ');
  const remainingWords = words.join(' ');

  return (
    <>
      <GradientText>{firstTwoWords} </GradientText>
      {remainingWords}
    </>
  );
};

export default Feature;
