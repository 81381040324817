import React, { useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
import { motion } from 'framer-motion';

const variants = {
  enter: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
};

const AnimatedStep = ({ children, previousStep: previousStepIndex }) => {
  const { activeStep } = useWizard();

  useEffect(() => {
    previousStepIndex.current = activeStep;
  }, [activeStep, previousStepIndex]);

  return (
    <motion.div
      custom={activeStep - previousStepIndex.current}
      variants={variants}
      initial="enter"
      animate="center"
    >
      {children}
    </motion.div>
  );
};

export default AnimatedStep;
