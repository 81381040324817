import React from 'react';
import { Box, Flex, Text, Link } from '@chakra-ui/react';

export const PhotoCreditLink = ({ isActive, pexelLink = '' }) => {
  return (
    <Box
      position={'absolute'}
      right={'0.5rem'}
      bottom={'0.5rem'}
      opacity={isActive ? 1 : 0}
    >
      <Flex display={'inline-flex'}>
        <Text fontSize="xs" color="white" pr={1}>
          Video on
        </Text>
        <Link
          target="_blank"
          rel="noreferrer"
          href={pexelLink}
          fontSize={'xs'}
          color="white"
          textDecoration={'underline'}
        >
          Pexels
        </Link>
      </Flex>
    </Box>
  );
};
