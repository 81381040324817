import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

import config from 'config';
import history from 'utils/history';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig = {
  domain: config.auth.domain,
  clientId: config.auth.clientId,
  ...(config.auth.audience ? { audience: config.auth.audience } : null),
  redirectUri: window.location.origin,
  scope: 'profile email read:current_user update:current_user_metadata',
  onRedirectCallback,
};

const AuthProvider = ({ children }) => (
  <Auth0Provider {...providerConfig}>{children}</Auth0Provider>
);

export default AuthProvider;
