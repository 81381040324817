import React from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = ({
  component: Component,
  layout: Layout,
  routes = [],
  path,
  ...rest
}) => {
  return (
    <Route path={path} {...rest}>
      <Layout routes={routes}>
        <Component />
      </Layout>
    </Route>
  );
};

export default PublicRoute;
