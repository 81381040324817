import React, { useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import CreateNewProject from './create-new-project';
import { NewProjectContext } from 'providers/new-project-context';

const NewProjectModal = () => {
  const { isOpen, setIsOpen } = useContext(NewProjectContext);
  console.log('new proj modal open', isOpen);

  const onClose = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    console.log(isOpen);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      data-testid="modalWindow"
      backgroundColor="shark"
    >
      <ModalOverlay />
      <ModalContent
        data-testid="modalContent"
        maxWidth={1100}
        backgroundColor="shark"
      >
        <ModalHeader
          as="h4"
          bg="woodsmoke"
          color="white"
          fontSize="18px"
          fontWeight="600"
          border="0.25px solid var(--chakra-colors-shark)"
          borderRadius="8px 8px 0px 0px"
          boxSizing="border-box"
        >
          Create New Project
        </ModalHeader>
        <ModalCloseButton backgroundColor={'red.400'} borderRadius={'full'} />
        <ModalBody
          bgColor="woodsmoke"
          color="white"
          pt={0}
          pr={0}
          pb={0}
          border="0.25px solid var(--chakra-colors-shark)"
        >
          <CreateNewProject />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewProjectModal;
