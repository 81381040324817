import React, { useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, SimpleGrid, Input } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useErrorHandler } from 'react-error-boundary';

import {
  Container,
  Header,
  HeaderTitle,
  Section,
  Content,
} from 'layouts/shared';
import {
  Block,
  FormControl,
  Button,
  ReactSelect,
  LoadingView,
} from 'components';
import ErrorBoundary from 'components/error-fallback';
import { convertFromRaw } from 'utils/react-select';
import useProfile from 'hooks/use-profile';
import useSaveProfile from 'hooks/use-save-profile';
import PaymentMethod from './payment-method';
import countriesJSON from '../countries.json';

const AccountBilling = () => {
  const { data, isLoading: isProfileLoading } = useProfile();
  const { mutateAsync, isLoading } = useSaveProfile();
  const handleError = useErrorHandler();
  const location = useLocation();

  const { control, handleSubmit, reset, register, watch, setValue } = useForm({
    defaultValues: {
      billing: {
        companyName: '',
        abn: '',
        country: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postcode: '',
      },
    },
  });

  useEffect(() => {
    if (!data) return;

    reset({
      billing: {
        companyName: data?.billing?.companyName,
        abn: data?.billing?.abn,
        country:
          convertFromRaw([data?.billing?.country].filter(Boolean))[0] ?? null,
        addressLine1: data?.billing?.addressLine1,
        addressLine2: data?.billing?.addressLine2,
        city: data?.billing?.city,
        state:
          convertFromRaw([data?.billing?.state].filter(Boolean))[0] ?? null,
        postcode: data?.billing?.postcode,
      },
    });
  }, [data, reset]);

  const onSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        billing: {
          companyName: values?.billing?.companyName,
          abn: values?.billing?.abn,
          country: values?.billing?.country?.value,
          addressLine1: values?.billing?.addressLine1,
          addressLine2: values?.billing?.addressLine2,
          city: values?.billing?.city,
          state: values?.billing?.state?.value,
          postcode: values?.billing?.postcode,
        },
      };

      return await mutateAsync({
        data: payload,
        email: data.email,
      });
    } catch (err) {
      handleError(err);
    }
  };

  const handleCountryChange = () => setValue('billing.state', null);

  const selectedCountry = watch('billing.country');

  const countries = useMemo(
    () => convertFromRaw(countriesJSON.countries, 'country'),
    []
  );

  const states = useMemo(() => {
    if (!selectedCountry) return null;

    return convertFromRaw(
      countriesJSON.countries.find(
        (value) => value.country === selectedCountry.value
      ).states
    );
  }, [selectedCountry]);

  if (isProfileLoading) return <LoadingView />;

  return (
    <Container>
      <Header>
        <HeaderTitle size={'lg'} fontWeight={700}>
          Billing & Subscriptions
        </HeaderTitle>
      </Header>

      <Section>
        {data?.stripePaymentMethodId !== null && (
          <Block>
            <HeaderTitle
              as={'h2'}
              pb={10}
              size={'md'}
              fontWeight={700}
              color={'gray.500'}
            >
              Payment Method
            </HeaderTitle>
            <PaymentMethod paymentMethodId={data?.stripePaymentMethodId} />
          </Block>
        )}

        {data?.stripeCustomerId !== null ? (
          <Block>
            <Content
              title={`Subscription ${data?.currentAccount?.plan?.name} Account`}
              text={data?.currentAccount?.plan?.description}
            >
              <Button
                as={Link}
                to={'/change-plan'}
                label={'Change plan'}
                colorScheme={'pink'}
                size="md"
              />
            </Content>
          </Block>
        ) : (
          <Block>
            <Content
              title={'Subscription'}
              text={
                'Clippy for Free. Try Clippy Pro and unlock all features and premium content.'
              }
            >
              <Button
                as={Link}
                to={{
                  pathname: `/pricing`,
                  state: { background: location },
                }}
                label={'Try Clippy Pro'}
                colorScheme={'brand.primary'}
                size="md"
              />
            </Content>
          </Block>
        )}
        <ErrorBoundary>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Block>
              <HeaderTitle
                as={'h2'}
                pb={10}
                size={'md'}
                fontWeight={700}
                color={'gray.500'}
              >
                Billing information for personal
              </HeaderTitle>
              <SimpleGrid columns={[1, 2]} spacing={5}>
                <FormControl
                  id={'billing.companyName'}
                  label={'Company name'}
                  helperText={'Leave blank to use your full name'}
                >
                  <Input
                    {...register('billing.companyName')}
                    name="billing.companyName"
                    placeholder="Enter your company name"
                    control={control}
                    borderColor={'inputBorder'}
                  />
                </FormControl>
                <FormControl
                  id={'billing.abn'}
                  label={'ABN'}
                  helperText={'This will appear on your invoice'}
                >
                  <Input
                    {...register('billing.abn')}
                    name="billing.abn"
                    placeholder="Enter your ABN"
                    control={control}
                    borderColor={'inputBorder'}
                  />
                </FormControl>
                <FormControl id={'billing.country'} label={'Country'}>
                  <ReactSelect
                    name="billing.country"
                    control={control}
                    options={countries}
                    onChange={handleCountryChange}
                    borderColor={'inputBorder'}
                  />
                </FormControl>
                <FormControl
                  id={'billing.state'}
                  label={'State/Province/Region'}
                >
                  <ReactSelect
                    name="billing.state"
                    control={control}
                    options={states}
                    borderColor={'inputBorder'}
                  />
                </FormControl>
                <FormControl
                  id={'billing.addressLine1'}
                  label={'Address Line 1'}
                >
                  <Input
                    {...register('billing.addressLine1')}
                    name="billing.addressLine1"
                    placeholder="Enter your Address Line 1"
                    borderColor={'inputBorder'}
                  />
                </FormControl>
                <FormControl
                  id={'billing.addressLine2'}
                  label={'Address Line 2'}
                  borderColor={'inputBorder'}
                >
                  <Input
                    {...register('billing.addressLine2')}
                    name="billing.addressLine2"
                    placeholder="Enter your Address Line 2"
                    borderColor={'inputBorder'}
                  />
                </FormControl>
                <FormControl id={'billing.city'} label={'City'}>
                  <Input
                    {...register('billing.city')}
                    name="billing.city"
                    placeholder=""
                    borderColor={'inputBorder'}
                  />
                </FormControl>
                <FormControl id={'billing.postcode'} label={'Zip/Postal Code'}>
                  <Input
                    {...register('billing.postcode')}
                    name="billing.postcode"
                    placeholder=""
                    borderColor={'inputBorder'}
                  />
                </FormControl>
              </SimpleGrid>
            </Block>
            <Box textAlign={'right'}>
              <Button
                type={'submit'}
                label={'Save Changes'}
                colorScheme={'pink'}
                size="md"
                isLoading={isLoading}
                loadingText={'Saving...'}
                isDisabled={isLoading}
              />
            </Box>
          </form>
        </ErrorBoundary>
      </Section>
    </Container>
  );
};

export default AccountBilling;
