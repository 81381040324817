import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import { format } from 'date-fns';

/**
 * Date and time format
 */
export const dateTimeFormat = (date, options = {}) => {
  if (date === undefined) return;

  const defaultOptions = { dateStyle: 'full', timeStyle: 'long' };

  return new Intl.DateTimeFormat('en-GB', {
    ...defaultOptions,
    ...options,
  }).format(new Date(date));
};

/**
 * Get month name
 */
export const getMonthName = (number) => {
  const d = new Date();
  d.setMonth(number - 1);

  const monthName = d.toLocaleString('default', { month: 'long' });
  return monthName;
};

/** get time ago description */
export const timeago = (ms) => {
  var ago = Math.floor(ms / 1000);
  var part = 0;

  if (ago < 2) {
    return 'a moment ago';
  }
  if (ago < 5) {
    return 'moments ago';
  }
  if (ago < 60) {
    return ago + ' seconds ago';
  }

  if (ago < 120) {
    return 'a minute ago';
  }
  if (ago < 3600) {
    while (ago >= 60) {
      ago -= 60;
      part += 1;
    }
    return part + ' minutes ago';
  }

  if (ago < 7200) {
    return 'an hour ago';
  }
  if (ago < 86400) {
    while (ago >= 3600) {
      ago -= 3600;
      part += 1;
    }
    return part + ' hours ago';
  }

  if (ago < 172800) {
    return 'a day ago';
  }
  if (ago < 604800) {
    while (ago >= 172800) {
      ago -= 172800;
      part += 1;
    }
    return part + ' days ago';
  }

  if (ago < 1209600) {
    return 'a week ago';
  }
  if (ago < 2592000) {
    while (ago >= 604800) {
      ago -= 604800;
      part += 1;
    }
    return part + ' weeks ago';
  }

  if (ago < 5184000) {
    return 'a month ago';
  }
  if (ago < 31536000) {
    while (ago >= 2592000) {
      ago -= 2592000;
      part += 1;
    }
    return part + ' months ago';
  }

  if (ago < 1419120000) {
    // 45 years, approximately the epoch
    return 'more than year ago';
  }

  // TODO pass in Date.now() and ms to check for 0 as never
  return 'never';
};

export const getFriendlyTime = (date) => {
  const ms = differenceInMilliseconds(new Date(), new Date(date));
  const timeAgo = timeago(ms);
  if (
    timeAgo.includes('days') ||
    timeAgo.includes('months') ||
    timeAgo.includes('year') ||
    timeAgo.includes('week') ||
    timeAgo.includes('never')
  ) {
    return format(new Date(date), 'PPP');
  }
  return timeAgo;
};

export const timestamp = () => {
  return Math.round(new Date().getTime() / 1000);
};

export const toISOStringWithTimezone = (date) => {
  // Get the timezone offset in minutes.
  const tzOffset = -date.getTimezoneOffset();
  // Get the sign of the timezone offset.
  const diff = tzOffset >= 0 ? '+' : '-';
  // Pad the number of hours so that it is always two digits long.
  const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
  return (
    // Format the date.
    `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${
      pad(date.getDate())
      // Format the time.
    }T${pad(date.getHours())}:${pad(date.getMinutes())}:${
      pad(date.getSeconds())
      // Format the timezone.
    }${diff}${pad(tzOffset / 60)}:${pad(tzOffset % 60)}`
  );
};
