import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Box } from '@chakra-ui/react';

import { HeaderTitle } from 'layouts/shared';
import { Button, Alert } from 'components';
import PaymentCardElement from './payment-card-element';
import usePaymentMethodIntent from '../hooks/use-payment-method-intent';

const PaymentMethodNew = ({ plan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useAuth0();

  const { email } = user;
  const { mutateAsync, isLoading } = usePaymentMethodIntent();

  const [message, setMessage] = useState(null);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setMessage(null);

      const paymentIntent = await mutateAsync({ email });
      const { clientSecret } = paymentIntent;

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: user?.name || user?.email,
          },
        },
      });

      if (result.error) {
        setMessage({
          value: result.error.message,
          type: 'error',
        });
      } else {
        // The payment has been processed!
        if (result.setupIntent.status === 'succeeded') {
          setMessage({
            value: 'Your new payment method has been saved successfully',
            type: 'success',
          });
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
        }
      }
    } catch (err) {
      setMessage({
        value: err.message,
        type: 'error',
      });
    }
  };

  return (
    <Box as={'form'} pt={4} pb={4} w={'100%'} onSubmit={handleSubmit}>
      <Box pb={4}>
        <HeaderTitle
          as={'h3'}
          pb={4}
          size={'md'}
          fontWeight={700}
          textAlign={'center'}
        >
          Add new payment method
        </HeaderTitle>
        {message && (
          <Alert description={message.value} status={message.type} mb={4} />
        )}
        <PaymentCardElement />
      </Box>
      <Button
        type={'submit'}
        label={'Save'}
        colorScheme={'pink'}
        size={'md'}
        isDisabled={!stripe || isLoading}
        isLoading={isLoading}
        isFullWidth
      />
    </Box>
  );
};

export default PaymentMethodNew;
