import React from 'react';
import NewProjectModal from './new-project-modal';
import { Box } from '@chakra-ui/react';

export const WithProjectModalHoc = (Component) => (props) => {
  return (
    <Box p={[2, 2, 4, 10, 10]} data-testid="with-project-modal-hoc-wrapper">
      <Component {...props} />
      <NewProjectModal />
    </Box>
  );
};
