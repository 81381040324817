import React, { useState } from 'react';
import { Card, CardContent, CardPhoto } from 'components';
import TemplateActions from './template-actions';
import { isEmpty } from 'lodash';
import { TemplateLottie } from './template-lottie';
import { PhotoCreditLink } from './photo-credit-link';

const Template = ({
  coverPhoto,
  title,
  videoFiles,
  user,
  pexelLink,
  videoId,
  duration,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [animData, setAnimData] = useState(null);
  const isLottie = coverPhoto && coverPhoto.includes('.json');

  React.useEffect(() => {
    const fetchData = async (url) => {
      const lottiedata = await fetch(url, {
        headers: {
          accept: 'application/json',
          contentType: 'application/json',
        },
      })
        .then((d) => d.json())
        .catch((err) => {
          console.log('error lottie file');
          console.log(err?.message);
        });
      console.log('lottie json data', lottiedata);
      setAnimData(lottiedata);
    };

    if (isLottie) {
      fetchData(coverPhoto);
    }
  }, [coverPhoto, isLottie]);

  const handleMouseEnter = () => setIsActive(true);

  const handleMouseLeave = () => {
    if (isOpen) return;
    setIsActive(false);
  };

  return (
    <Card
      padding={0}
      borderRadius={0}
      cursor={'pointer'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid="cardlayout-template"
    >
      <CardContent>
        {isLottie && !isEmpty(animData) && (
          <>
            <TemplateLottie animData={animData} />
          </>
        )}
        {!isLottie && <CardPhoto src={coverPhoto} title={title} />}
        <TemplateActions
          isActive={isActive}
          isOpen={isOpen}
          setIsActive={setIsActive}
          setIsOpen={setIsOpen}
          title={title}
          videoFiles={videoFiles}
          user={user}
          pexelLink={pexelLink}
          videoId={videoId}
          duration={duration}
        />
        <PhotoCreditLink isActive={isActive} pexelLink={pexelLink} />
      </CardContent>
    </Card>
  );
};

export default Template;
