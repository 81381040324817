import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Text, Link, Icon } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoHomeFill } from 'react-icons/go';
import { Button } from 'components';
import { NewProjectContext } from 'providers/new-project-context';

const AsideNav = ({ routes }) => {
  const { setIsOpen } = useContext(NewProjectContext);
  return (
    <React.Fragment>
      <AsideNavItem to={'/dashboard'}>
        <AsideNavItemIcon>
          <Icon as={GoHomeFill} w={8} h={8} opacity={1} pt={1} ml={-1} />
        </AsideNavItemIcon>
        <AsideNavItemTitle>{'Home'}</AsideNavItemTitle>
      </AsideNavItem>

      {routes
        .filter((f) => f.name !== 'Home')
        .map((navItem) => (
          <AsideNavItem to={navItem.path} key={navItem.id}>
            <AsideNavItemIcon>
              <FontAwesomeIcon
                style={{ fontSize: '1.4em' }}
                icon={['fas', navItem.icon]}
              />
            </AsideNavItemIcon>

            <AsideNavItemTitle>{navItem.name}</AsideNavItemTitle>
          </AsideNavItem>
        ))}
    </React.Fragment>
  );
};

const AsideNavItem = ({ to, children }) => (
  <Link
    as={NavLink}
    to={to}
    display={'flex'}
    py={'1.5rem'}
    px={'1rem'}
    height={'2.5rem'}
    alignItems={'center'}
    borderRadius={'0.25rem'}
    transition={'background-color 200ms, opacity 200ms'}
    color={'#9393A2'}
    whiteSpace={'nowrap'}
    textDecoration={'none'}
    _hover={{
      backgroundColor: '#262630',
    }}
    _activeLink={{
      backgroundColor: '#262630',
      fontWeight: '700',
      border: '1px solid #000',
    }}
    _notFirst={{
      my: '0.5rem',
    }}
  >
    {children}
  </Link>
);

const AsideNavItemIcon = ({ children }) => (
  <Text as={'span'} margin={0} padding={'0 1rem 0 0'}>
    {children}
  </Text>
);

const AsideNavItemTitle = ({ children }) => (
  <Text as={'span'} display={'block'} whiteSpace={'nowrap'}>
    {children}
  </Text>
);

export default AsideNav;
