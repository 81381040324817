import { normalize, schema } from 'normalizr';
const userProjects = new schema.Entity('projects', {});

/**
[{
    data: {isLoading: false, creating: false, updating: false, error: "",},
    id: "65279d00f852b0f95a5a8062",
    projectid: "65279d00f852b0f95a5a8062",
    updatedAt: "2023-10-12T22:24:50+11:00",
    user: "irfan@trolio.com"
}] 
 */
const userProjectsSchema = [userProjects];
export const normalizeProjects = (data) => normalize(data, userProjectsSchema);
