import React, { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Heading } from '@chakra-ui/react';
import { startCase } from 'lodash';

import { IconButton, MenuItem, Menu } from 'components';

const ProjectActions = ({
  onCopy = () => null,
  onExport = () => null,
  onRemove = () => null,
  setIsActive,
  setIsOpen,
  isActive,
  isOpen,
  title,
  nickname,
}) => {
  const [baseElement, setBaseElement] = useState(null);

  const ref = useOnclickOutside(
    () => {
      setIsActive(false);
      setIsOpen(false);
    },
    { disabled: !isOpen }
  );

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsActive((prev) => !prev);
    setIsOpen((prev) => !prev);
  };

  const handleOnCopy = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsActive(false);
    setIsOpen(false);
    onCopy();
  };

  const handleOnExport = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsActive(false);
    setIsOpen(false);
    onExport();
  };

  const handleOnRemove = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsActive(false);
    setIsOpen(false);
    onRemove();
  };

  return (
    <Box position={'absolute'} right={'0.5rem'} top={'0.5rem'}>
      <IconButton
        ref={setBaseElement}
        onClick={handleClick}
        ariaLabel="actions"
        size="xs"
        variant={'solid'}
        isRound
        colorScheme={'blue'}
        opacity={isActive || isOpen ? 1 : 0}
        transition={'opacity 200ms ease'}
        icon={<FontAwesomeIcon icon={['fas', 'ellipsis-h']} size="sm" />}
      />

      <Menu
        ref={ref}
        baseElement={baseElement}
        isOpen={isOpen}
        overrideStyles={{
          nav: { minWidth: '12rem' },
        }}
      >
        <ProjectInfo title={title} nickname={nickname} />
        <MenuItem title={'Make a copy'} icon={'copy'} onClick={handleOnCopy} />
        <MenuItem
          title={'Export'}
          icon={'file-export'}
          onClick={handleOnExport}
        />
        <MenuItem
          title={'Move to trash'}
          icon={'trash'}
          onClick={handleOnRemove}
        />
      </Menu>
    </Box>
  );
};

const ProjectInfo = ({ title, nickname }) => (
  <Box borderBottom={'1px solid #2C2D37'} p={'1rem'} textAlign={'left'}>
    <Heading as="h1" size="md" isTruncated pb={'0.25rem'} fontWeight={700}>
      {title}
    </Heading>
    <Heading size="xs" fontWeight={300}>
      {`by ${startCase(nickname) || 'Daniel Platypus'}`}
    </Heading>
  </Box>
);

export default ProjectActions;
