import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import {
  Container,
  Header,
  HeaderTitle,
  Section,
  Content,
} from 'layouts/shared';
import { Block, Button, EditableForm } from 'components';
import config from '../../../config';
import { dateTimeFormat } from 'utils/date';
import PasswordForm from './password-form';

const AccountSecurity = () => {
  const { user } = useAuth0();

  const lastPasswordReset = dateTimeFormat(
    user?.[config.auth.claims.lastPasswordReset]
  );

  return (
    <Container>
      <Header>
        <HeaderTitle size={'lg'} fontWeight={700}>
          Login & Security
        </HeaderTitle>
      </Header>

      <Section>
        <Block>
          <EditableForm
            title={'Password'}
            text={
              lastPasswordReset && `Password last updated: ${lastPasswordReset}`
            }
          >
            <PasswordForm />
          </EditableForm>
        </Block>
        <Block>
          <Content
            title={'Delete your account'}
            text={
              'By deleting your account, you’ll no longer be able to any access Clippy and your account data will be permanently deleted.'
            }
          >
            <Button label={'Delete account'} colorScheme={'red'} size="md" />
          </Content>
        </Block>
      </Section>
    </Container>
  );
};

export default AccountSecurity;
