import { useMutation, useQueryClient } from 'react-query';

import constants from '../../../constants';
import useApi from 'hooks/use-api';

const usePaymentMethodIntent = () => {
  const queryClient = useQueryClient();
  const api = useApi();

  const getPaymentMethodIntent = async ({ email }) => {
    const response = await api.get(
      `/${constants.endpoints.users}/${email}/${constants.endpoints.paymentMethods}/${constants.endpoints.intents}`,
      {}
    );

    return {
      ...response?.data?.data,
    };
  };

  return useMutation(
    (data) =>
      getPaymentMethodIntent(data).then((data) => {
        return data;
      }),
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries('paymentMethod');
      },
    }
  );
};

export default usePaymentMethodIntent;
