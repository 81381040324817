import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Text, List, ListItem } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container, Header, HeaderTitle, Section } from 'layouts/shared';
import { Block } from 'components';
import ErrorBoundary from 'components/error-fallback';
import { plans } from 'utils/plans';
import PaymentMethod from './payment-method';

const Signup = () => {
  const { plan } = useParams();

  const selectedPlan = plans[plan];

  return (
    <ErrorBoundary>
      <Container>
        <Header>
          <HeaderTitle size={'lg'} fontWeight={700}>
            Clippy Sign up
          </HeaderTitle>
        </Header>

        <Section>
          <Block display={'flex'} color={'gray.500'}>
            <Box flex={1}>
              <HeaderTitle as={'h2'} pb={4} size={'md'} fontWeight={700}>
                {selectedPlan.name}
              </HeaderTitle>
              <Text pb={4}>{selectedPlan.description}</Text>

              <List pb={4} spacing={3}>
                {selectedPlan.features.map((feature) => (
                  <ListItem key={feature}>
                    <Box as={'span'} mr={4} color={'blue.500'}>
                      <FontAwesomeIcon icon={['fas', 'check-circle']} />
                    </Box>
                    {feature}
                  </ListItem>
                ))}
              </List>
            </Box>

            <Box flex={1}>
              <PaymentMethod plan={plan} />
            </Box>
          </Block>
        </Section>
      </Container>
    </ErrorBoundary>
  );
};

export default Signup;
