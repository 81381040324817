import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Box } from '@chakra-ui/react';

import { HeaderTitle } from 'layouts/shared';
import { Button, Alert } from 'components';
import { plans } from 'utils/plans';
import PaymentCardElement from './payment-card-element';
import useCreateSubscription from '../hooks/use-create-subscription';

const PaymentMethod = ({ plan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useAuth0();
  const history = useHistory();
  const { mutateAsync } = useCreateSubscription();

  const [stripeError, setStripeError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const selectedPlan = plans[plan];

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setStripeError(null);
      setIsLoading(true);

      const payload = {
        email: user.email,
        productId: selectedPlan.productId,
        priceId: selectedPlan.monthlyPriceId,
      };
      const subscription = await mutateAsync(payload);
      const { clientSecret } = subscription;

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: user?.name || user?.email,
          },
        },
      });

      if (result.error) {
        setStripeError(result.error.message);
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          history.push('/account/billing');
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box as={'form'} w={'100%'} onSubmit={handleSubmit}>
      <Box pb={4}>
        <HeaderTitle as={'h3'} pb={4} size={'sm'} fontWeight={700}>
          Payment method
        </HeaderTitle>
        {stripeError && <Alert description={stripeError} mb={4} />}
        <PaymentCardElement />
      </Box>
      <Button
        type={'submit'}
        label={'Confirm'}
        colorScheme={'pink'}
        size={'md'}
        isDisabled={!stripe || isLoading}
        isLoading={isLoading}
        loadingText={'Confirming...'}
        isFullWidth
      />
    </Box>
  );
};

export default PaymentMethod;
