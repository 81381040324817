import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import config from '../config';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.stripe.publishableKey);

const ElementsProvider = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default ElementsProvider;
