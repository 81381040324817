import React from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';

const Button = ({
  onClick = () => null,
  variant = 'solid',
  label,
  size = 'md',
  isFullWidth = false,
  ...rest
}) => {
  return (
    <ChakraButton
      onClick={onClick}
      variant={variant}
      size={size}
      isFullWidth={isFullWidth}
      {...rest}
    >
      {label}
    </ChakraButton>
  );
};

export default Button;
