const Input = {
  variants: {
    outline: {
      field: {
        borderColor: 'gray.700',
        _focus: {
          borderColor: 'blue.500',
          boxShadow: 'none',
        },
        _invalid: {
          boxShadow: 'none',
        },
      },
    },
  },
};

export default Input;
