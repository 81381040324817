import React, { useCallback } from 'react';
import { Route, useHistory } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

const ModalRoute = ({ component: Component, path, ...rest }) => {
  const history = useHistory();

  const handleClose = useCallback(() => history.goBack(), [history]);

  return (
    <Route
      path={path}
      children={
        <Modal onClose={handleClose} size={'full'} isOpen={true} {...rest}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Component />
            </ModalBody>
          </ModalContent>
        </Modal>
      }
      {...rest}
    />
  );
};

export default ModalRoute;
