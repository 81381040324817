export const experienceOptions = [
  'Never edited',
  'Basic experience',
  'Intermediate experience',
  'Advanced video editor',
];
export const roleOptions = [
  'Marketer',
  'Influencer',
  'Video Editor',
  'Vendor',
  'Developer',
  'Product Designer',
  'UI/UX Desginer',
  'Other',
];
export const occupationOptions = [
  'The company I work for',
  'My business',
  'Non-profit or Charity',
  'School project',
  'Fun',
  'Other',
];
export const shareOptions = [
  'Facebook',
  'Instagram',
  'TikTok',
  'Snapchat',
  'YouTube',
  'LinkedIn',
  'Website',
  'Other',
];
