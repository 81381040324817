import { Search2Icon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import React from 'react';

const SearchInput = (props) => {
  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        fontSize="xs"
        children={<Search2Icon color="dolphin" />}
      />
      <Input
        type="text"
        placeholder="search"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      />
    </InputGroup>
  );
};

export default SearchInput;
