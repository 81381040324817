import React from 'react';
import { SimpleGrid, useCheckboxGroup } from '@chakra-ui/react';

import { useOnboarding } from '../provider';
import CheckboxCard from './checkbox-card';

const options = [
  {
    value: 'Marketer',
    src: '📈',
  },
  {
    value: 'Influencer',
    src: '🎙',
  },
  {
    value: 'Video Editor',
    src: '🎥',
  },
  {
    value: 'Vendor',
    src: '🛍',
  },
  {
    value: 'Developer',
    src: '💻',
  },
  {
    value: 'Product Designer',
    src: '📦',
  },
  {
    value: 'UI/UX Desginer',
    src: '🎨',
  },
  {
    value: 'Other',
    src: '❓',
  },
];

const StepTwo = () => {
  const onboarding = useOnboarding();

  const { getCheckboxProps } = useCheckboxGroup({
    name: 'role',
    defaultValue: onboarding?.state?.role,
    onChange: (val) =>
      onboarding.dispatch({ type: 'update', data: { role: val } }),
  });

  return (
    <SimpleGrid minChildWidth={'8rem'} spacing={5}>
      {options.map(({ src, value }) => {
        const checkbox = getCheckboxProps({ value });

        return (
          <CheckboxCard key={value} {...checkbox} src={src}>
            {value}
          </CheckboxCard>
        );
      })}
    </SimpleGrid>
  );
};

export default React.memo(StepTwo);
