import React, { useEffect, useMemo } from 'react';
import { Box, SimpleGrid, Input, Spacer } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useErrorHandler } from 'react-error-boundary';

import { Container, Header, HeaderTitle, Section } from 'layouts/shared';
import {
  Block,
  FormControl,
  Button,
  ReactSelect,
  Switch,
  LoadingView,
} from 'components';
import ErrorBoundary from 'components/error-fallback';
import { convertFromRaw, convertToRaw } from 'utils/react-select';
import useProfile from 'hooks/use-profile';
import useSaveProfile from 'hooks/use-save-profile';
import {
  experienceOptions,
  roleOptions,
  occupationOptions,
  shareOptions,
} from '../select-options';
import { useAuth0 } from '@auth0/auth0-react';

import { UserButton } from 'components';

const AccountSettings = () => {
  const { data, isLoading: isProfileLoading } = useProfile();
  const { mutateAsync, isLoading } = useSaveProfile();
  const handleError = useErrorHandler();
  const { user } = useAuth0();
  const { picture } = user;

  const experience = useMemo(() => convertFromRaw(experienceOptions), []);
  const role = useMemo(() => convertFromRaw(roleOptions), []);
  const occupation = useMemo(() => convertFromRaw(occupationOptions), []);
  const share = useMemo(() => convertFromRaw(shareOptions), []);

  const { control, handleSubmit, reset, register } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      preferences: {
        experience: [],
        role: [],
        occupation: [],
        share: [],
      },
      darkMode: true,
    },
  });

  useEffect(() => {
    if (!data) return;

    reset({
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      mobile: data?.mobile,
      preferences: {
        experience: convertFromRaw(data?.preferences?.experience),
        role: convertFromRaw(data?.preferences?.role),
        occupation: convertFromRaw(data?.preferences?.occupation),
        share: convertFromRaw(data?.preferences?.share),
      },
      darkMode: true,
    });
  }, [data, reset]);

  const onSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        preferences: {
          experience: convertToRaw(values.preferences.experience),
          role: convertToRaw(values.preferences.role),
          occupation: convertToRaw(values.preferences.occupation),
          share: convertToRaw(values.preferences.share),
        },
      };

      return await mutateAsync({
        data: payload,
        email: data.email,
      });
    } catch (err) {
      handleError(err);
    }
  };

  if (isProfileLoading) return <LoadingView />;

  return (
    <ErrorBoundary>
      <Container>
        <Header>
          <HeaderTitle size={'lg'} fontWeight={700}>
            My Account
          </HeaderTitle>
        </Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Section>
            <Block>
              <HeaderTitle
                as={'h2'}
                pb={5}
                size={'md'}
                fontWeight={700}
                color={'gray.500'}
              >
                Personal Information
              </HeaderTitle>
              <UserButton size={'lg'} p={0} picture={picture} {...user} />
              <Spacer pb={4} />
              <SimpleGrid columns={[1, 2]} spacing={5}>
                <FormControl id={'firstName'} label={'First Name'}>
                  <Input
                    {...register('firstName')}
                    name="firstName"
                    placeholder="First Name"
                    borderColor={'inputBorder'}
                  />
                </FormControl>
                <FormControl id={'lastName'} label={'Last Name'}>
                  <Input
                    {...register('lastName')}
                    name="lastName"
                    placeholder="Last Name"
                    borderColor={'inputBorder'}
                  />
                </FormControl>
                <FormControl id={'mobile'} label={'Mobile Number'}>
                  <Input
                    {...register('mobile')}
                    name="mobile"
                    placeholder="Mobile Number"
                    borderColor={'inputBorder'}
                  />
                </FormControl>
                <FormControl id={'email'} label={'Email Address'} isReadOnly>
                  <Input
                    {...register('email')}
                    name="email"
                    placeholder="Email Address"
                    isReadOnly
                    borderColor={'inputBorder'}
                  />
                </FormControl>
              </SimpleGrid>
            </Block>
            <Block>
              <SimpleGrid columns={[1, 2]} spacing={5}>
                <FormControl
                  id={'preferences.experience'}
                  label={'What’s your video editing experience?'}
                >
                  <ReactSelect
                    name="preferences.experience"
                    control={control}
                    isMulti
                    options={experience}
                  />
                </FormControl>
                <FormControl
                  id={'preferences.role'}
                  label={'What best described your role?'}
                >
                  <ReactSelect
                    name="preferences.role"
                    control={control}
                    isMulti
                    options={role}
                  />
                </FormControl>
                <FormControl
                  id={'preferences.occupation'}
                  label={'What will you be using Clippy for?'}
                >
                  <ReactSelect
                    name="preferences.occupation"
                    control={control}
                    isMulti
                    options={occupation}
                  />
                </FormControl>
                <FormControl
                  id={'preferences.share'}
                  label={'Where will you share your videos?'}
                >
                  <ReactSelect
                    name="preferences.share"
                    control={control}
                    isMulti
                    options={share}
                  />
                </FormControl>
              </SimpleGrid>
            </Block>
            <Block>
              <FormControl
                id={'darkMode'}
                label={'Dark Mode'}
                display="flex"
                flexDir="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Switch
                  {...register('darkMode')}
                  id="darkMode"
                  name="Dark Mode"
                  control={control}
                  size="md"
                />
              </FormControl>
            </Block>
            <Box textAlign={'right'}>
              <Button
                type={'submit'}
                label={'Save Changes'}
                colorScheme={'pink'}
                size="md"
                isLoading={isLoading}
                isDisabled={isLoading}
                loadingText={'Saving...'}
              />
            </Box>
          </Section>
        </form>
      </Container>
    </ErrorBoundary>
  );
};

export default AccountSettings;
