import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  Box,
  Text,
} from '@chakra-ui/react';

import { HeaderTitle } from 'layouts/shared';
import { Button, Block } from 'components';
import ErrorBoundary from 'components/error-fallback';
import useProfile from 'hooks/use-profile';
import useUpdateSubscription from '../hooks/use-update-subscription';

const ChangePlanConfirmModal = ({
  oldPlan,
  newPlan,
  isOpen,
  onOpen,
  onClose,
  ...rest
}) => {
  const { data: profile } = useProfile();
  const { mutateAsync, isLoading } = useUpdateSubscription();

  const handleCloseClick = () => {
    onClose();
  };

  const handleConfirmClick = async () => {
    await mutateAsync({
      email: profile?.email,
      subscriptionId: profile?.stripeSubscriptionId,
      productId: newPlan?.productId,
      priceId: newPlan?.monthlyPriceId,
    });

    onClose();
  };

  return (
    <Modal onClose={handleCloseClick} size={'md'} isOpen={isOpen} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Clippy plan</ModalHeader>
        <ModalCloseButton />
        <ErrorBoundary>
          <ModalBody>
            <Block>
              <Box pr={4} color={'gray.500'}>
                <HeaderTitle as={'h3'} pb={4} size={'sm'} fontWeight={700}>
                  CURRENT PLAN: {oldPlan?.name}
                </HeaderTitle>
                <Text pb={[4, 0]}>
                  {oldPlan?.description} {`A${oldPlan?.monthlyAmount} /month`}
                </Text>
              </Box>
            </Block>
            <Block>
              <Box pr={4} color={'gray.500'}>
                <HeaderTitle as={'h3'} pb={4} size={'sm'} fontWeight={700}>
                  NEW PLAN: {newPlan?.name}
                </HeaderTitle>
                <Text pb={[4, 0]}>
                  {newPlan?.description} {`A${newPlan?.monthlyAmount} /month`}
                </Text>
              </Box>
            </Block>
          </ModalBody>
          <ModalFooter pt={0}>
            <ButtonGroup spacing="4">
              <Button
                label={'Confirm change'}
                colorScheme={'pink'}
                size="md"
                isLoading={isLoading}
                loadingText={'Saving...'}
                isDisabled={isLoading}
                onClick={handleConfirmClick}
              />
              <Button
                label={'Cancel'}
                colorScheme={'brand.primary'}
                size="md"
                onClick={onClose}
                isDisabled={isLoading}
              />
            </ButtonGroup>
          </ModalFooter>
        </ErrorBoundary>
      </ModalContent>
    </Modal>
  );
};

export default ChangePlanConfirmModal;
