import { useMutation, useQueryClient } from 'react-query';

import constants from '../../../constants';
import useApi from 'hooks/use-api';

const useUpdateSubscription = () => {
  const queryClient = useQueryClient();
  const api = useApi();

  const updateSubscription = async ({
    email,
    subscriptionId,
    productId,
    priceId,
  }) => {
    const subscriptionResponse = await api.put(
      `/${constants.endpoints.users}/${email}/${constants.endpoints.subscriptions}/${subscriptionId}`,
      { productId, priceId }
    );
    const { data: subscriptionData } = subscriptionResponse.data;

    return subscriptionData;
  };

  return useMutation(
    (data) =>
      updateSubscription(data).then((data) => {
        return data;
      }),
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries('profile');
      },
      useErrorBoundary: true,
    }
  );
};

export default useUpdateSubscription;
