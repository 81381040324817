import { useMutation, useQueryClient } from 'react-query';

import constants from '../../../constants';
import useApi from 'hooks/use-api';

const useCreateSubscription = () => {
  const queryClient = useQueryClient();
  const api = useApi();

  const createSubscription = async ({ email, productId, priceId }) => {
    await api.post(
      `/${constants.endpoints.users}/${email}/${constants.endpoints.customers}`,
      {}
    );

    const subscriptionResponse = await api.post(
      `/${constants.endpoints.users}/${email}/${constants.endpoints.subscriptions}`,
      { productId, priceId }
    );
    const { data: subscriptionData } = subscriptionResponse.data;

    return subscriptionData;
  };

  return useMutation(
    (data) =>
      createSubscription(data).then((data) => {
        return data;
      }),
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries('profile');
      },
    }
  );
};

export default useCreateSubscription;
