import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Button, Input } from 'components';
import ColorPicker from 'components/colorPicker/ColorPicker';
import BoxWrapper from 'components/box-wrapper/box-wrapper';
import React from 'react';
import { useForm } from 'react-hook-form';
import RecentProjectContent from './recent-projects-content';
import useProjects from 'hooks/use-projects';
import { isEmpty } from 'lodash';

const defaultValues = {
  projectName: '',
  projectSettings: 'more about project...',
  template: 'RECENT',
  templateShape: 'Original',
  background: '#262730',
};

const canvasSizes = {
  original: {
    width: 1920,
    height: 1080,
    orientation: 'Landscape',
    description: 'Youtube and websites',
    ratio: '16:9',
  },
  square: {
    width: 1080,
    height: 1080,
    orientation: 'Square',
    description: 'Twitter, Instagram and Facebook',
    ratio: '1:1',
  },
  story: {
    width: 1080,
    height: 1920,
    orientation: 'Portrait',
    description: 'Instagram & Facebook stories, Snapchat and TikTok',
    ratio: '9:16',
  },
  landscape: {
    width: 1920,
    height: 1536,
    dimensions: { width: 1920, height: 1536 },
    orientation: 'Landscape',
    description: 'Twitter, Instagram and Facebook',
    ratio: '5:4',
  },
  facebookPost: {
    width: 940,
    height: 788,
    orientation: 'Landscape',
    description: 'Facebook',
    ratio: '5:4',
  },
  cover: {
    width: 851,
    height: 315,
    orientation: 'Landscape',
    description: 'Facebook cover',
    ratio: '2.7:1',
  },
};

const rules = {
  projectName: { required: 'Project name is required' },
  customWidth: {
    required: 'Custom width is required',
    pattern: {
      value: /^[0-9]*$/,
      message: 'Only numbers are allowed',
    },
  },
  customHeight: {
    required: 'Custom height is required',
    pattern: {
      value: /^[0-9]*$/,
      message: 'Only numbers are allowed',
    },
  },
};

const templateTypes = {
  RECENT: 'RECENT',
  TEMPLATE: 'TEMPLATE',
  SAVED: 'SAVED',
};

const templateVal = {
  ORIGNAL: 'Original',
  SQUARE: 'Square',
  LANDSCAPE: 'Landscape',
  STORY: 'Story',
};

const CreateNewProject = () => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [showCustomSettings, setShowCustomSettings] = React.useState(false);

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues });

  const { postProject, currentProject } = useProjects();

  const selectedTemplate = watch('template');
  const templateShape = watch('templateShape');
  const background = watch('background');
  const projectName = watch('projectName');
  const customHeight = watch('customHeight');
  const customWidth = watch('customWidth');
  let size = canvasSizes[templateShape?.toLocaleLowerCase()];
  if (showCustomSettings) {
    size = {
      width: customWidth,
      height: customHeight,
      orientation: 'Custom',
      description: 'Custom',
      ratio: `${customWidth}:${customHeight}`,
    };
  }

  /**final forms submit */
  const onSubmit = () => {
    if (isEmpty(errors)) {
      setIsSubmitted(true);
      postProject({
        defaults: {
          backgroundColor: background,
        },
        title: projectName,
        size: size,
      });
    }
  };

  React.useEffect(() => {
    const { isLoading, isSuccess, isError, id } = currentProject;
    if (isSubmitted) {
      if (!isLoading && isSuccess && !!id) {
        handleOnNewProject(id);
        setIsSubmitted(false);
      } else if (!isLoading && isError) {
        setIsSubmitted(false);
      }
    }
  }, [isSubmitted, currentProject]);

  const handleOnTemplateClick = (text) => {
    setValue('templateShape', text);
  };

  const setButtonBackground = (type) =>
    selectedTemplate === type ? 'azure' : 'charade';

  const handleOnNewProject = (id) => {
    window.location.replace(`https://app.clippy.io/canvas/${id}`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex data-testid="root-panel" flexDir="column">
        <Flex flexDir="row" data-testid="flexview01">
          <Flex flex="1" flexDir="column" data-testid="gridlayout01">
            {/* right side **/}
            <Flex>
              {/** Button Group */}

              <ButtonGroup
                isAttached
                variant="outline"
                spacing={1}
                mt={4}
                mb={4}
                bg="charade"
                size="sm"
                borderRadius={5}
              >
                <Button
                  borderRadius={5}
                  size="sm"
                  bg={setButtonBackground(templateTypes.RECENT)}
                  border="1px solid var(--chakra-colors-azure)"
                  borderColor={setButtonBackground(templateTypes.RECENT)}
                  width="118px"
                  onClick={() => {
                    setValue('template', templateTypes.RECENT);
                  }}
                  label="Recent"
                />
                <Button
                  size="sm"
                  borderRadius={5}
                  bg={setButtonBackground(templateTypes.TEMPLATE)}
                  borderColor={setButtonBackground(templateTypes.TEMPLATE)}
                  width="118px"
                  onClick={() => {
                    setValue('template', templateTypes.TEMPLATE);
                  }}
                  label="Templates"
                />
                <Button
                  size="sm"
                  borderRadius={5}
                  bg={setButtonBackground(templateTypes.SAVED)}
                  borderColor={setButtonBackground(templateTypes.SAVED)}
                  width="118px"
                  onClick={() => {
                    setValue('template', templateTypes.SAVED);
                  }}
                  label="Saved"
                />
              </ButtonGroup>
            </Flex>
            <Flex pb={12}>
              {/** Layouts */}
              <Box mt={4}>
                <Text mb={3} fontSize="sm">
                  Blank Project Presets
                </Text>
                <HStack spacing="24px">
                  <BoxWrapper
                    text="Original"
                    active={templateShape === templateVal.ORIGNAL}
                    onClick={handleOnTemplateClick}
                  >
                    <Box
                      border="1px"
                      borderStyle="dashed"
                      borderRadius={5}
                      w="75px"
                      h="93px"
                    ></Box>
                  </BoxWrapper>
                  <BoxWrapper
                    text="Square"
                    caption={'1:1'}
                    active={templateShape === templateVal.SQUARE}
                    onClick={handleOnTemplateClick}
                  >
                    <Box
                      border="1px"
                      borderStyle="dashed"
                      borderRadius={5}
                      w="75px"
                      h="75px"
                    ></Box>
                  </BoxWrapper>

                  <BoxWrapper
                    text="Landscape"
                    caption={'16:9'}
                    active={templateShape === templateVal.LANDSCAPE}
                    onClick={handleOnTemplateClick}
                  >
                    <Box
                      border="1px"
                      borderStyle="dashed"
                      borderRadius={5}
                      w="75px"
                      h="42px"
                    ></Box>
                  </BoxWrapper>
                  <BoxWrapper
                    text="Story"
                    caption={'9:16'}
                    active={templateShape === templateVal.STORY}
                    onClick={handleOnTemplateClick}
                  >
                    <Box
                      border="1px"
                      borderStyle="dashed"
                      borderRadius={5}
                      w="53px"
                      h="95px"
                    ></Box>
                  </BoxWrapper>
                </HStack>
                <Box mt={6}>
                  <Text mb={3} fontSize="sm">
                    Templates
                  </Text>
                  <RecentProjectContent />
                </Box>
              </Box>
            </Flex>
          </Flex>
          <Flex
            flexDir="column"
            alignItems="center"
            justifyContent="space-between"
            data-testid="gridlayout02"
            border="0.15px solid var(--chakra-colors-shark)"
            borderRadius="0px 0px 8px 8 px"
            boxSizing="border-box"
          >
            {/* right side **/}
            <Box pt={6} pb={6} paddingRight={5} paddingLeft={5}>
              <VStack spacing={4} align="stretch">
                <FormControl
                  id="name"
                  size="lg"
                  isInvalid={errors?.projectName?.message}
                >
                  <FormLabel>Project name</FormLabel>
                  <Input
                    name="projectName"
                    control={control}
                    type="text"
                    size="lg"
                    placeholder="Flex Pods Max"
                    _placeholder={{ fontSize: '12px' }}
                    rules={rules.projectName}
                    borderColor="inputBorder"
                  />
                  <FormHelperText color="danger">
                    {errors?.projectName?.message}
                  </FormHelperText>
                </FormControl>
                <Button
                  bg="studio"
                  borderRadius={4}
                  label="Use Custom Settings"
                  onClick={() => setShowCustomSettings(!showCustomSettings)}
                />
                {showCustomSettings && (
                  <VStack maxW={250}>
                    <Text fontSize="sm" color="grey">
                      Custom settings will allow you to set the canvas size of
                      your choice.
                    </Text>

                    <HStack>
                      <FormControl
                        id="customWidth"
                        size="lg"
                        isInvalid={errors?.customWidth?.message}
                      >
                        <Input
                          name="customWidth"
                          control={control}
                          type="text"
                          size="md"
                          placeholder="1080"
                          _placeholder={{ fontSize: '12px' }}
                          rules={rules.customWidth}
                          borderColor="inputBorder"
                        />
                      </FormControl>
                      <FormControl
                        id="customHeight"
                        size="lg"
                        isInvalid={errors?.customHeight?.message}
                      >
                        <Input
                          name="customHeight"
                          control={control}
                          type="text"
                          size="md"
                          placeholder="1920"
                          _placeholder={{ fontSize: '12px' }}
                          rules={rules.customHeight}
                          borderColor="inputBorder"
                        />
                      </FormControl>
                    </HStack>
                    <Spacer />
                  </VStack>
                )}
                <HStack>
                  <FormLabel> Background</FormLabel>
                  <ColorPicker
                    value={background}
                    onChange={(e) => setValue('background', e)}
                  />
                </HStack>
              </VStack>
            </Box>
            <Box pt={6} pb={6} paddingRight={5} paddingLeft={5} width="100%">
              <Button
                isLoading={isSubmitted}
                loadingText="Submitting"
                bg="razzleRose"
                borderRadius={4}
                isFullWidth
                type="submit"
                label="Create New Project"
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
};

export default CreateNewProject;
