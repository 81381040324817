import React from 'react';
import { Flex, Box, Image, Heading } from '@chakra-ui/react';

import { Container, Section, Title, GradientText, ComingSoon } from '../shared';
import previewImage from 'assets/images/clippy-editor-preview-001.svg';

const FeatureApp = ({ data, direction }) => {
  return (
    <Container maxW="container.xl" centerContent>
      <Section
        mb={12}
        p={[6, null, null, 12, 12]}
        border={`2px solid #535665`}
        backgroundColor="#262730"
        borderRadius={'3xl'}
      >
        <ComingSoon />

        <Flex
          direction="column"
          align="center"
          textAlign="center"
          sx={{
            '> *': {
              mb: 10,
            },
          }}
        >
          <Box>
            <Title mb={6}>
              Don’t let your{' '}
              <GradientText>storytelling be restricted</GradientText>
            </Title>

            <Heading as="h2" fontSize={['xs', 'lg', null, null]}>
              Continue your video editing on the go with with all the power you
              need in palm of your hands.
            </Heading>
          </Box>

          <Box>
            <Image
              src={previewImage}
              loading="lazy"
              alt="Clippy video editor preview"
            />
          </Box>
        </Flex>
      </Section>
    </Container>
  );
};

export default FeatureApp;
