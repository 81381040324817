import React from 'react';
import { Box } from '@chakra-ui/react';

const SliderItem = ({ children, ...rest }) => (
  <Box minW={'23%'} mx={'0.5rem'} {...rest}>
    {children}
  </Box>
);

export default SliderItem;
