import React from 'react';
import { Flex, Image, Link, Box, useMediaQuery } from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from 'assets/images/clippy-logo.svg';

const Header = ({
  children,
  redirectPath = '/dashboard',
  onDrawerClick,
  isOpen,
  ...props
}) => {
  const [isSmallScreen] = useMediaQuery('(max-width: 600px)');

  return (
    <Flex
      px={[2, 2, 2, 2, 10]}
      h={'4.5rem'}
      alignItems={'center'}
      justifyContent={'space-between'}
      borderBottom={'2px solid transparent'}
      {...props}
      sx={{
        borderImage:
          'linear-gradient(291.95deg, #E952D7 0.39%, #58F6E6 50.36%, #648CFD 99.68%)',
        borderImageSlice: '1',
        ...props?.sx,
      }}
    >
      <Box display={['block', 'none']} onClick={onDrawerClick}>
        <FontAwesomeIcon icon={['fa', 'bars']} />
      </Box>
      <Flex>
        <Link as={ReactLink} to={redirectPath}>
          <Image src={logo} alt={'Clippy'} />
        </Link>
      </Flex>
      <Flex
        alignItems={['center', 'center']}
        data-testid="logo-text"
        gap={[1, 1, 5, 5]}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default Header;
