/**
 * Generates react select options
 *
 * @param arr
 * @returns { value: *, label: * }
 */
export const convertFromRaw = (arr, key = null) =>
  arr.map((item) => ({ value: item[key] || item, label: item[key] || item }));

/**
 * Converts react select options to array
 *
 * @param arr
 * @returns [string]
 */
export const convertToRaw = (arr) => arr.map((item) => item.value);
