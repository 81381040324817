import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

import TemplateTags from './template-tags';
import TemplateSlider from './template-slider';
import usePexelsApi from 'hooks/use-pexels';

const Templates = () => {
  const { setSearchTerm, data, isLoading } = usePexelsApi();

  const handleTagClick = (value) => {
    setSearchTerm(value);
  };

  return (
    <Box py={10}>
      <Heading as={'h1'} mb={8} fontWeight={700} size={'xl'}>
        Start from a template
      </Heading>

      <Box mb={8}>
        <TemplateTags onClick={handleTagClick} />
      </Box>

      <TemplateSlider data={data} isLoading={isLoading} />
    </Box>
  );
};

export default Templates;
