import React, { useContext } from 'react';
import { get, merge, find, omit, isEmpty, orderBy } from 'lodash';
import { useQueryClient } from 'react-query';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { ApiContext } from '../providers/api-provider';
import { toISOStringWithTimezone } from '../utils/date';
import { normalizeProjects } from 'normalizr/user-projects-schema';
import { generateClipTemplate } from 'utils/generateClipTemplate';

const remotionApiEndPoint =
  'https://9din4tc0m7.execute-api.ap-southeast-2.amazonaws.com/dev/remotionprojects';

const awsProjectApiEndpoint =
  'https://9din4tc0m7.execute-api.ap-southeast-2.amazonaws.com/dev';

export default function useProjects() {
  // Get QueryClient from the context
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(10);
  const { user } = useAuth0();

  //'626b4b458a68e2c20e0b05e9'
  // const [projectId, setProjectId] = React.useState('626b4b458a68e2c20e0b05e9');
  const [projects, setProjects] = React.useReducer((_, action) => action, {
    isIdle: true,
  });

  const [currentProject, setCurrentProjectState] = React.useReducer(
    (_, action) => action,
    {
      isIdle: true,
    }
  );

  /** Axios client */
  const _axios = useContext(ApiContext);
  /**
   * Get Remotion Projects for the cover photo urls
   */
  const getRemotionProjects = React.useCallback(
    async (userId) => {
      const apiUrl = `${remotionApiEndPoint}/get-user-projects-cover-photo/${userId}`;
      const result = await _axios.get(apiUrl).then((res) => res.data);
      const data = get(result, 'data', []);
      return data;
    },
    [_axios]
  );

  const getRemotionProjectsByUser = React.useCallback(
    async (userId) => {
      const apiUrl = `${remotionApiEndPoint}/get-user-projects/${userId}`;
      const result = await _axios.get(apiUrl).then((res) => res.data);
      const data = get(result, 'data', []);
      return data;
    },
    [_axios]
  );

  const getProjectAssetsByUser = React.useCallback(
    async (userId) => {
      const apiUrl = `${awsProjectApiEndpoint}/projectassets/users/${userId}`;
      const result = await _axios.get(apiUrl).then((res) => res.data);
      const data = get(result, 'data', []);
      const normalizedData = normalizeProjects(data);
      console.log('noralized data', normalizedData);
      const normalizedProjData = get(normalizedData, 'entities.projects', {});
      const sortedProjects = orderBy(
        normalizedProjData,
        ['updatedAt'],
        ['desc']
      );
      return sortedProjects;
    },
    [_axios]
  );

  const getProjects = React.useCallback(async () => {
    setProjects({ isLoading: true });
    try {
      const result = await _axios
        .get('/projects/me?sort=-createdAt')
        .then((res) => res.data);
      const { data = [] } = result;
      setProjects({ isSuccess: true, data });
      return data;
    } catch (error) {
      setProjects({ isError: true, error });
    }
  }, [_axios]);

  const getProjectsByQuery = React.useCallback(
    async (pageNumber = 1, limit = 10) => {
      const result = await _axios
        .get(`/projects/me?page=${pageNumber}&limit=${limit}&sort=-createdAt`)
        .then((res) => res.data);
      const { data = [] } = result;
      return data;
    },
    [_axios]
  );

  const postProject = React.useCallback(
    async (projectDetails) => {
      try {
        setCurrentProjectState({
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: null,
        });
        const values = merge(
          {
            zoomValue: '1',
            totalDuration: 5,
            compositionData: {
              videos: [],
              audios: [],
              titles: [],
              shapes: [],
              gifs: [],
              images: [],
              emojis: [],
            },
          },
          projectDetails
        );
        const { data } = await _axios
          .post('/projects', JSON.stringify(values))
          .then((res) => res.data);
        const newProjectId = get(data, '_id');
        let templateData = null;
        let tempwidth = null;
        let tempheight = null;
        if (data && data.template) {
          const firstTemplate = get(data.template, '[0]', {});
          if (!isEmpty(firstTemplate)) {
            tempwidth = get(firstTemplate, 'width', 0);
            tempheight = get(firstTemplate, 'height', 0);
            templateData = generateClipTemplate(firstTemplate, newProjectId);
          }
        }

        const remotionPayload = {
          data: {
            clips: templateData ? [templateData] : [],
            width: tempwidth ? tempwidth : data.size.width,
            height: tempheight ? tempheight : data.size.height,
            defaults: values.defaults || {},
          },
          title: projectDetails.title,
          status: 'ACTIVE',
          id: data._id,
          projectid: data._id,
          updatedAt: toISOStringWithTimezone(new Date()),
          user: user.email || user.name,
        };

        // post to aws dynamodb
        await _axios
          .post(
            `https://9din4tc0m7.execute-api.ap-southeast-2.amazonaws.com/dev/projectassets`,
            JSON.stringify(remotionPayload),
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then((res) => res.data);
        // invalidate the cache for react-query projects FETCH_PROJECTS_BY_PAGE_NUMBER
        queryClient.invalidateQueries({
          queryKey: ['FETCH_PROJECTS_BY_USER'],
        });

        setCurrentProjectState({
          isLoading: false,
          isSuccess: true,
          id: newProjectId,
          data,
        });
      } catch (error) {
        setCurrentProjectState({ isLoading: false, isError: true, error });
      }
    },
    [_axios, queryClient, user]
  );

  const postRemotionProject = React.useCallback(
    async (projectdata, projectId) => {
      try {
        const getProjectById = find(projectdata, (f) => f.id === projectId);
        const copiedPayload = omit(getProjectById, ['id', 'createdAt']);

        // post to mongodb
        const result = await _axios
          .post(
            `https://yevhye03b1.execute-api.ap-southeast-2.amazonaws.com/dev/projects`,
            JSON.stringify(copiedPayload)
          )
          .then((res) => res.data);
        const { data = [] } = result;
        const remotionPayload = {
          data: copiedPayload.data,
          title: `Copy of ${getProjectById.title}` || 'Copy of Flex Max Pro',
          status: 'ACTIVE',
          id: data._id,
          projectid: data._id,
          updatedAt: toISOStringWithTimezone(new Date()),
          user: user.email || user.name,
        };

        // post to aws dynamodb
        const remotionPostProject = await _axios
          .post(
            `https://9din4tc0m7.execute-api.ap-southeast-2.amazonaws.com/dev/projectassets`,
            JSON.stringify(remotionPayload),
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then((res) => res.data);

        // invalidate the cache for react-query projects FETCH_PROJECTS_BY_PAGE_NUMBER
        queryClient.invalidateQueries({
          queryKey: ['FETCH_PROJECTS_BY_USER'],
        });

        return remotionPostProject;
      } catch (error) {
        throw error;
      }
    },
    [_axios, queryClient, user]
  );

  // update project to delete status
  const trashProject = React.useCallback(
    async (projectdata, projectId) => {
      try {
        const getProjectById = find(projectdata, (f) => f.id === projectId);
        const remotionPayload = {
          ...getProjectById,
          updatedAt: toISOStringWithTimezone(new Date()),
          status: 'TRASHED',
        };
        // post to aws dynamodb
        const remotionPostProject = await _axios
          .put(
            `https://9din4tc0m7.execute-api.ap-southeast-2.amazonaws.com/dev/projectassets/status`,
            JSON.stringify(remotionPayload),
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then((res) => res.data);
        // invalidate the cache for react-query projects FETCH_PROJECTS_BY_PAGE_NUMBER
        queryClient.invalidateQueries({
          queryKey: ['FETCH_PROJECTS_BY_USER'],
        });
        return remotionPostProject;
      } catch (error) {
        throw error;
      }
    },
    [_axios, queryClient]
  );

  // update project to delete status
  const restoreProject = React.useCallback(
    async (projectdata, projectId) => {
      try {
        const getProjectById = find(projectdata, (f) => f.id === projectId);
        const remotionPayload = {
          ...getProjectById,
          updatedAt: toISOStringWithTimezone(new Date()),
          status: 'ACTIVE',
        };
        // post to aws dynamodb
        const remotionPostProject = await _axios
          .put(
            `https://9din4tc0m7.execute-api.ap-southeast-2.amazonaws.com/dev/projectassets/status`,
            JSON.stringify(remotionPayload),
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then((res) => res.data);
        // invalidate the cache for react-query projects FETCH_PROJECTS_BY_PAGE_NUMBER
        queryClient.invalidateQueries({
          queryKey: ['FETCH_PROJECTS_BY_USER'],
        });
        return remotionPostProject;
      } catch (error) {
        throw error;
      }
    },
    [_axios, queryClient]
  );

  // update project to delete status
  const deleteProject = React.useCallback(
    async (projectId) => {
      try {
        // post to aws dynamodb
        const remotionPostProject = await _axios
          .delete(
            `https://9din4tc0m7.execute-api.ap-southeast-2.amazonaws.com/dev/projectassets/${projectId}`,
            JSON.stringify({}),
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then((res) => res.data);
        // invalidate the cache for react-query projects FETCH_PROJECTS_BY_PAGE_NUMBER
        queryClient.invalidateQueries({
          queryKey: ['FETCH_PROJECTS_BY_USER'],
        });
        return remotionPostProject;
      } catch (error) {
        throw error;
      }
    },
    [_axios, queryClient]
  );

  const {
    data: projectsByUser,
    isLoading: isloadingAllProjects,
    isError: isErrorAllProjects,
  } = useQuery(
    ['FETCH_PROJECTS_BY_USER', user.name],
    async () => {
      return await getProjectAssetsByUser(user.email || user.name);
    },
    { refetchOnReconnect: false, refetchOnWindowFocus: false }
  );

  // Implement the pagination logic based on the number rows in the projectsByUser

  return {
    projects,
    getProjects,
    currentProject,
    getProjectsByQuery,
    getRemotionProjects,
    postProject,
    getRemotionProjectsByUser,
    postRemotionProject,
    getProjectAssetsByUser,

    allProjects: projectsByUser,
    setCurrentPage,
    currentPage,
    setPageLimit,
    pageLimit,
    isLoading: isloadingAllProjects,
    isError: isErrorAllProjects,
    /** delete project status */
    deleteProject,
    restoreProject,
    trashProject,
  };
}
