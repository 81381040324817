import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Link,
} from '@chakra-ui/react';
import ReactPlayer from 'react-player';

export const TemplatePreview = ({
  isOpen = false,
  videoPreviewLink = '',
  user,
  pexelLink,
  onClose = (f) => f,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody minH={'300px'}>
          <Flex width="100%" height="100%" className="player-wrapper">
            <ReactPlayer
              url={videoPreviewLink}
              className="react-player"
              playing
              width="410px"
              height="340px"
              controls
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex display={'inline-flex'}>
            <Text fontSize="xs" color="white" pr={1}>
              Video By{' '}
            </Text>
            <Link
              target="_blank"
              rel="noreferrer"
              href={user?.url}
              fontSize={'xs'}
              color="blue"
              pr={1}
            >
              {user?.name}
            </Link>
            <Text fontSize="xs" color="white" pr={1}>
              on
            </Text>
            <Link
              target="_blank"
              rel="noreferrer"
              href={pexelLink}
              fontSize={'xs'}
              color="white"
              textDecoration={'underline'}
            >
              Pexels
            </Link>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
