export const plans = {
  free: {
    name: 'Free',
    description: 'Everything you need to start video editing',
    monthlyAmount: '0.00',
    yearlyAmount: '0.00',
    features: [
      '20 second video editing limit',
      'Watermarked videos',
      '720p quality at 30fps',
      '30 day video retention',
    ],
    isMostPopular: false,
    isFree: true,
    contactUs: false,
    path: '/dashboard',
  },
  basic: {
    name: 'Basic',
    description:
      'Best for a simple video edit that doesn’t need premium features.',
    monthlyAmount: '9.99',
    yearlyAmount: '9.99',
    productId: 'prod_KjgJLeTWVImZh5',
    monthlyPriceId: 'price_1K4CwfENHsjDAGXVi9PQtpku',
    yearlyPriceId: 'price_1K4CwfENHsjDAGXVc3dmm5y5',
    features: [
      'Everything Basic has, plus:',
      '5 minute video editing limit',
      'No watermarks',
      '1080p qaulity at 60fps',
      '1 year video retention',
    ],
    isMostPopular: false,
    isFree: false,
    contactUs: false,
    path: '/signup/basic',
  },
  pro: {
    name: 'Pro',
    description:
      'Professional video edits made easy with access to premium features and content.',
    monthlyAmount: '24.99',
    yearlyAmount: '24.99',
    productId: 'prod_KjgKY9u6FfMdKO',
    monthlyPriceId: 'price_1K4CxRENHsjDAGXV0F37U9pf',
    yearlyPriceId: 'price_1K4CxRENHsjDAGXV2630BJKA',
    features: [
      'Everything Basic has, plus:',
      '30 minute video editing limit',
      'No scaling',
      'Unlimited video retention',
      'Access to premium features',
      'Access to premium content',
    ],
    isMostPopular: true,
    isFree: false,
    contactUs: false,
    path: '/signup/pro',
  },
  enterprise: {
    name: 'Enterprise',
    description:
      'Bring Clippy to your organization and access exclusive features. ',
    monthlyAmount: '0.00',
    yearlyAmount: '0.00',
    features: [
      'Everything Pro has, plus:',
      'No video editing limit',
      'Enterprise level-support',
    ],
    isMostPopular: false,
    isFree: false,
    contactUs: true,
    path: '/contact',
  },
};
