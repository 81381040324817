import React from 'react';
import { Flex } from '@chakra-ui/react';

const MobileNav = ({ children }) => {
  return (
    <Flex
      as={'nav'}
      border={['2px solid #535665', 'unset']}
      width="100%"
      py={6}
      direction="column"
      alignItems="center"
      borderRadius="3xl"
      bg="#1A1C23"
      position="absolute"
      left="0"
      top="4.5rem"
      zIndex="1"
    >
      <Flex direction="column" alignItems="center">
        {children}
      </Flex>
    </Flex>
  );
};

export default MobileNav;
