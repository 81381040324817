import React from 'react';
import { SimpleGrid, useCheckboxGroup } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useOnboarding } from '../provider';
import CheckboxCard from './checkbox-card';

const options = [
  {
    value: 'Facebook',
    src: { type: 'fab', icon: 'facebook' },
  },
  {
    value: 'Instagram',
    src: { type: 'fab', icon: 'instagram' },
  },
  {
    value: 'TikTok',
    src: { type: 'fab', icon: 'tiktok' },
  },
  {
    value: 'Snapchat',
    src: { type: 'fab', icon: 'snapchat' },
  },
  {
    value: 'YouTube',
    src: { type: 'fab', icon: 'youtube' },
  },
  {
    value: 'LinkedIn',
    src: { type: 'fab', icon: 'linkedin' },
  },
  {
    value: 'Website',
    src: { type: 'fas', icon: 'globe' },
  },
  {
    value: 'Other',
    src: { type: 'fas', icon: 'question-circle' },
  },
];

const StepFour = () => {
  const onboarding = useOnboarding();

  const { getCheckboxProps } = useCheckboxGroup({
    name: 'share',
    defaultValue: onboarding?.state?.share,
    onChange: (val) =>
      onboarding.dispatch({ type: 'update', data: { share: val } }),
  });

  return (
    <SimpleGrid minChildWidth={'8rem'} spacing={5}>
      {options.map(({ src, value }) => {
        const checkbox = getCheckboxProps({ value });

        return (
          <CheckboxCard
            key={value}
            {...checkbox}
            src={src}
            render={(src) => (
              <FontAwesomeIcon size={'2x'} icon={[src.type, src.icon]} />
            )}
          >
            {value}
          </CheckboxCard>
        );
      })}
    </SimpleGrid>
  );
};

export default React.memo(StepFour);
