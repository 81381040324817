import React from 'react';
import { Box } from '@chakra-ui/react';

const Layout = ({ children }) => {
  return (
    <LayoutWrapper>
      <LayoutMain>{children}</LayoutMain>
    </LayoutWrapper>
  );
};

const LayoutWrapper = ({ children }) => (
  <Box
    width={'100%'}
    minH={'100vh'}
    backgroundColor={'#1A1C22'}
    color={'white'}
  >
    {children}
  </Box>
);

const LayoutMain = ({ children }) => children;

export default Layout;
