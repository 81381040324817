import React from 'react';
import {
  Alert as ChakraAlert,
  AlertTitle,
  AlertIcon,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react';

const Alert = ({
  status = 'error',
  variant = 'subtle',
  title,
  description,
  showClose = false,
  onClose = () => null,
  children,
  ...rest
}) => {
  return (
    <ChakraAlert
      status={status}
      variant={variant}
      borderRadius={'0.25rem'}
      {...rest}
    >
      <AlertIcon />
      {title && <AlertTitle mr={2}>{title}</AlertTitle>}
      {description && <AlertDescription>{description}</AlertDescription>}
      {showClose && (
        <CloseButton
          onClick={onClose}
          position="absolute"
          right="8px"
          top="8px"
        />
      )}
    </ChakraAlert>
  );
};

export default Alert;
