const Menu = {
  parts: ['menu', 'item'],
  baseStyle: {
    menu: {
      boxShadow: 'lg',
      rounded: 'lg',
      flexDirection: 'column',
      py: '2',
      bg: 'gray.600',
    },
    item: {
      fontWeight: 'medium',
      lineHeight: 'normal',
      color: 'gray.600',
    },
    _focus: {
      boxShadow: 'none',
    },
  },
  variants: {
    'ghost-primary': {
      color: '#9393A2',
      _hover: {
        bg: '#262630',
      },
    },
  },
};

export default Menu;
