import React from 'react';
import {
  Box,
  Flex,
  FormLabel,
  Button,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  ButtonGroup,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  MenuOptionGroup,
  MenuItemOption,
} from '@chakra-ui/react';
import constants from 'constants';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import { useAuth0 } from '@auth0/auth0-react';
import { SizeChangerPagination } from 'components';
import { isEmpty, map, size, filter } from 'lodash';
import userProjectsHook from 'hooks/use-projects';
import { LoadingView } from 'components';
import { BsBoxArrowUpRight, BsFillTrashFill } from 'react-icons/bs';
import { SearchInput } from 'components';
import { useFontSizes } from 'hooks/useFontSizes';

export const UserLibrary = () => {
  const { user } = useAuth0();
  console.log('user', user);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(10);
  const [search, setSearch] = React.useState('');
  const [, setSortBy] = React.useState('asc');
  const { allProjects, isLoading } = userProjectsHook();
  const { fontSize, headingFontSize } = useFontSizes();

  const startIndex = (currentPage - 1) * pageLimit;
  const endIndex = startIndex + pageLimit;

  const currentPageProjects = React.useMemo(() => {
    const modifiedProj = map(
      filter(allProjects, (f) => f.status === constants.status.ACTIVE),
      (proj) => proj
    );
    return modifiedProj.length > 0
      ? modifiedProj.slice(startIndex, endIndex)
      : [];
  }, [allProjects, startIndex, endIndex]);

  const totalPages = !isEmpty(currentPageProjects)
    ? Math.ceil(size(currentPageProjects) / pageLimit)
    : 0;
  const hasPrevPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // const filterSearch = React.useCallback(
  //   debounce((searchKey) => {
  //     if (!isEmpty(projectsData)) {
  //       if (isNil(searchKey?.trim())) {
  //         setCurrentItems(projectsData?.docs);
  //       } else {
  //         const filterData = projectsData.docs.filter((proj) =>
  //           proj.title?.toLowercase().includes(searchKey?.toLowercase())
  //         );
  //         setCurrentItems(filterData);
  //       }
  //     }
  //   }, 1000),
  //   [projectsData]
  // );

  const handleOnProjectClick = (id) => {
    window.location.replace(`https://app.clippy.io/canvas/${id}`);
  };

  const header = ['name', 'created', 'actions'];
  const color1 = useColorModeValue('gray.400', 'gray.400');
  const color2 = useColorModeValue('gray.400', 'gray.400');

  return (
    <>
      <Box p={0} data-testid="library-page-wrapper">
        <Flex mb={8} alignItems={'center'} justifyContent={'space-between'}>
          <Heading as={'h1'} fontWeight={700} size={fontSize}>
            Library
          </Heading>
        </Flex>
        <Flex
          mb={5}
          flexDir={'row'}
          flex={1}
          justifyContent="flex-start"
          align={'center'}
          alignItems="center"
          gap={8}
        >
          <Flex alignItems="center" justifyContent="center">
            <FormLabel>Filter</FormLabel>
            <SearchInput
              value={search}
              onChange={(evt) => {
                setSearch(evt.target.value);
                // filterSearch(evt.target.value);
              }}
            />
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            <Menu bgColor={'#262630'}>
              <MenuButton
                variant="solid"
                colorScheme="gray.300"
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                Sort By
              </MenuButton>
              <MenuList bgColor={'#262630'}>
                <MenuOptionGroup defaultValue="asc" title="Order" type="radio">
                  <MenuItemOption onClick={() => setSortBy('asc')} value="asc">
                    Ascending
                  </MenuItemOption>
                  <MenuItemOption
                    onClick={() => setSortBy('desc')}
                    value="desc"
                  >
                    Descending
                  </MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
        <Flex
          w="full"
          bgColor={'#262630'}
          _dark={{ bg: '#3e3e3e' }}
          p={5}
          alignItems="center"
          justifyContent="center"
          flexDir="column"
        >
          <Table
            w="full"
            bg={{ bg: 'gray.800' }}
            _dark={{ bg: 'gray.800' }}
            display={{
              base: 'block',
              md: 'table',
            }}
            sx={{
              '@media print': {
                display: 'table',
              },
            }}
          >
            <Thead
              display={{
                base: 'none',
                md: 'table-header-group',
              }}
              sx={{
                '@media print': {
                  display: 'table-header-group',
                },
              }}
            >
              <Tr>
                {header.map((x) => (
                  <Th key={x}>{x}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody
              display={{
                base: 'block',
                lg: 'table-row-group',
              }}
              sx={{
                '@media print': {
                  display: 'table-row-group',
                },
              }}
            >
              <>{isLoading && <LoadingView />}</>
              <>
                {!isLoading &&
                  map(currentPageProjects, (proj, tid) => {
                    return (
                      <Tr
                        key={tid}
                        display={{
                          base: 'grid',
                          md: 'table-row',
                        }}
                        sx={{
                          '@media print': {
                            display: 'table-row',
                          },
                          gridTemplateColumns:
                            'minmax(0px, 35%) minmax(0px, 65%)',
                          gridGap: '8px',
                        }}
                      >
                        <React.Fragment>
                          <Td
                            display={{
                              base: 'table-cell',
                            }}
                            sx={{
                              '@media print': {
                                display: 'none',
                              },
                              textTransform: 'uppercase',
                              color: color1,
                              fontSize: 'xs',
                              fontWeight: 'bold',
                              letterSpacing: 'wider',
                              fontFamily: 'heading',
                            }}
                          >
                            {proj.title || `project 0${tid}`}
                          </Td>
                          <Td
                            color={'gray.500'}
                            fontSize="md"
                            fontWeight="hairline"
                          >
                            {format(new Date(proj.updatedAt), 'PPP')}
                          </Td>
                        </React.Fragment>
                        <Td
                          display={{
                            base: 'table-cell',
                            md: 'none',
                          }}
                          sx={{
                            '@media print': {
                              display: 'none',
                            },
                            textTransform: 'uppercase',
                            color: color2,
                            fontSize: 'xs',
                            fontWeight: 'bold',
                            letterSpacing: 'wider',
                            fontFamily: 'heading',
                          }}
                        >
                          Actions
                        </Td>
                        <Td>
                          <ButtonGroup variant="solid" size="sm" spacing={3}>
                            <IconButton
                              colorScheme="blue"
                              icon={<BsBoxArrowUpRight />}
                              aria-label="Up"
                              onClick={() => handleOnProjectClick(proj.id)}
                            />
                            <IconButton
                              colorScheme="red"
                              variant="outline"
                              icon={<BsFillTrashFill />}
                              aria-label="Delete"
                            />
                          </ButtonGroup>
                        </Td>
                      </Tr>
                    );
                  })}
              </>
            </Tbody>
          </Table>
        </Flex>
      </Box>
      {!isEmpty(currentPageProjects) && (
        <SizeChangerPagination
          totalPages={totalPages}
          currentPage={currentPage}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
          itemsPerPage={pageLimit}
          onClick={(val) => setCurrentPage(val)}
          pageLimit={pageLimit}
          setPageLimit={(val) => setPageLimit(val)}
        />
      )}
    </>
  );
};
