const constants = {
  endpoints: {
    users: 'users',
    me: 'users/me',
    auth: 'auth',
    customers: 'customers',
    subscriptions: 'subscriptions',
    paymentMethods: 'payment-methods',
    intents: 'intents',
    pexel_videos: 'videos/search',
  },
  paymentMethods: {
    visa: 'visa',
    americanExpress: 'american express',
    masterCard: 'masterCard',
    discover: 'discover',
    jcd: 'jcd',
    dinersClub: 'diners club',
    unknown: 'unknown',
  },
  status: {
    ACTIVE: 'ACTIVE',
    TRASHED: 'TRASHED',
  },
};

export default constants;
