import React from 'react';
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Center,
  Spinner,
  VStack,
} from '@chakra-ui/react';

export const TemplateLoadingSpinner = ({
  isOpen = false,
  onClose = (f) => f,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalCloseButton />
        <ModalBody minH={'300px'} display={'flex'}>
          <Flex flex={1} justify={'center'} className="player-wrapper">
            <Center>
              <VStack flex={1}>
                <Spinner colorScheme={'whiteAlpha'} color="white" size="xl" />
                <Text size="xxl" color={'white'}>
                  processing template...
                </Text>
              </VStack>
            </Center>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
