import React from 'react';
import { Flex, Box, Heading, Text } from '@chakra-ui/react';

export const Container = ({ children }) => <Box p={10}>{children}</Box>;

export const Header = ({ children, ...rest }) => (
  <Box
    as={'header'}
    mb={6}
    pb={6}
    borderBottomWidth={'thin'}
    borderStyle={'solid'}
    borderColor={'shark'}
    {...rest}
  >
    {children}
  </Box>
);

export const HeaderTitle = ({ children, ...rest }) => (
  <Heading color={'gray.500'} {...rest}>
    {children}
  </Heading>
);

export const Section = ({ children }) => <Box as={'section'}>{children}</Box>;

export const Content = ({ title, text, renderContent = null, children }) => (
  <Flex
    alignItems={['flex-start', 'center']}
    justifyContent={'space-between'}
    direction={['column', 'row']}
    _notLast={{
      pb: 8,
    }}
  >
    {renderContent?.({ title, text }) || (
      <Box pr={4} color={'gray.500'}>
        <HeaderTitle as={'h3'} pb={4} size={'sm'} fontWeight={700}>
          {title}
        </HeaderTitle>
        <Text pb={[4, 0]}>{text}</Text>
      </Box>
    )}
    <Box>{children}</Box>
  </Flex>
);
