import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Flex,
  Heading,
  Button,
  Text,
  Image,
} from '@chakra-ui/react';

const Hero = () => {
  return (
    <Container maxW="container.xl" centerContent>
      <Flex
        pt={[8, null, null, 24]}
        direction="column"
        align="center"
        textAlign="center"
        sx={{
          '> *': {
            mb: 10,
          },
        }}
      >
        <Heading as="h1" fontSize={['3xl', '4xl', null, '8xl']}>
          The
          <Text
            as="span"
            sx={{
              bg: 'linear-gradient(291.95deg, #E952D7 0.39%, #58F6E6 50.36%, #648CFD 99.68%)',
              bgClip: 'text',
              textFillColor: 'transparent',
            }}
          >
            {' '}
            most powerful
          </Text>
          <br />
          video editing platform.
        </Heading>
        <Heading as="h2" fontSize={['xs', 'lg', null, null]}>
          Clippy allows you to edit on the go,
          <br /> wherever you are.
        </Heading>
        <Link to="/dashboard">
          <Button colorScheme="pink" size="lg">
            Try Clippy for free
          </Button>
        </Link>
      </Flex>

      <Image
        borderStyle={'solid'}
        borderColor={'transparent'}
        borderWidth={[12, null, null, 30]}
        sx={{
          borderImage:
            'linear-gradient(291.95deg, #E952D7 0.39%, #58F6E6 50.36%, #648CFD 99.68%)',
          borderImageSlice: '1',
          borderRadius: 6,
        }}
        src="https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/clippy-editor-preview-001.svg"
        alt="Clippy video editor preview"
      />
    </Container>
  );
};

export default Hero;
