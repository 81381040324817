import React from 'react';
import { Box, Center, Spinner } from '@chakra-ui/react';
import { Slider, SliderItem } from 'components';
import Template from './template';

// const imageUrls = [
//   'https://eyecastvideoeditormediafiles194906-dev.s3.ap-southeast-2.amazonaws.com/public/assets/lottiefiles/3532-car.json',
//   'https://eyecastvideoeditormediafiles194906-dev.s3.ap-southeast-2.amazonaws.com/public/assets/lottiefiles/126757-car-fuel.json',
//   'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-009.jpeg',
//   'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-008.jpeg',
//   'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-007.jpeg',
//   'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-006.jpeg',
//   'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-005.jpeg',
//   'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-004.jpg',
//   'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-003.jpg',
//   'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-002.jpg',
//   'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-001.jpg',
//   'https://eyecastvideoeditormediafiles194906-dev.s3.ap-southeast-2.amazonaws.com/public/assets/lottiefiles/141242-road-trip.json',
// ];

const TemplateSlider = ({ isLoading = false, data = [] }) => {
  return (
    <Box p={5} borderRadius={'0.25rem'} bgColor={'#262630'}>
      <Slider>
        {isLoading && (
          <Center height={'200px'}>
            <Spinner size="xl" />
          </Center>
        )}
        {!isLoading && data.length > 0 && (
          <>
            {data.map((vid, index) => (
              <SliderItem
                key={vid.id}
                mx={'1rem'}
                minWidth={'12.5rem'}
                zIndex={1}
                maxHeight={'150px'}
              >
                <Template
                  videoId={vid.id}
                  coverPhoto={vid.image}
                  title={`Template ${vid.id}`}
                  videoFiles={vid.video_files}
                  user={vid.user}
                  pexelLink={vid.pexelLink}
                  duration={vid.duration}
                />
              </SliderItem>
            ))}
          </>
        )}
      </Slider>
    </Box>
  );
};

export default TemplateSlider;
