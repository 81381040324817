import React, { useRef, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Box, Heading, Text } from '@chakra-ui/react';
import { Wizard, useWizard } from 'react-use-wizard';
import { AnimatePresence } from 'framer-motion';
import { useErrorHandler } from 'react-error-boundary';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import OnboardingLayout from 'layouts/onboarding';
import { ACOUNT_NAV_ITEMS } from 'routes/nav-items';
import { Button } from 'components';
import ErrorBoundary from 'components/error-fallback';
import useProfile from 'hooks/use-profile';
import useSaveProfile from 'hooks/use-save-profile';

import OnboardingProvider from '../provider';
import { useOnboarding } from '../provider';
import AnimatedStep from './animated-step';
import StepOne from './step-one';
import StepTwo from './step-two';
import StepThree from './step-three';
import StepFour from './step-four';

const steps = [
  {
    id: 'stepOne',
    title: 'What’s your video editing experience?',
    text: 'We’ll use this to learn more about you and recommend templates especially for you.',
    component: StepOne,
  },
  {
    id: 'stepTwo',
    title: 'What best desribes your role?',
    text: 'We’ll use this to learn more about you and recommend templates especially for you.',
    component: StepTwo,
  },
  {
    id: 'stepThree',
    title: 'What will you be using Clippy for?',
    text: 'We’ll use this to learn more about you and recommend templates especially for you.',
    component: StepThree,
  },
  {
    id: 'stepFour',
    title: 'Where will you share your videos?',
    text: 'We’ll use this to learn more about you and recommend templates especially for you.',
    component: StepFour,
  },
];

const Onboarding = () => {
  return (
    <OnboardingLayout routes={ACOUNT_NAV_ITEMS}>
      <ErrorBoundary>
        <OnboardingProvider>
          <OnboardingWizard />
        </OnboardingProvider>
      </ErrorBoundary>
    </OnboardingLayout>
  );
};

const OnboardingWizard = () => {
  const previousStep = useRef(0);

  const onboarding = useOnboarding();
  const { dispatch } = onboarding;

  const { data } = useProfile();

  useEffect(() => {
    if (!data) return;

    dispatch({ type: 'update', data: data?.preferences });
  }, [data, dispatch]);

  return (
    <Flex
      minHeight={'calc(100vh - 72px)'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <AnimatePresence>
        <Box p={10} width={'43rem'} height={'33rem'} bgColor={'#17151D'}>
          <Wizard header={<Header />} footer={<Footer />}>
            {steps.map(({ id, component: Step }) => (
              <AnimatedStep key={id} previousStep={previousStep}>
                <Step />
              </AnimatedStep>
            ))}
          </Wizard>
        </Box>
      </AnimatePresence>
    </Flex>
  );
};

const Header = () => {
  const { activeStep, previousStep } = useWizard();
  const step = steps[activeStep];

  return (
    <Box pb={6}>
      <Flex pb={6}>
        {activeStep !== 0 && (
          <Flex
            alignItems={'center'}
            color={'green.400'}
            cursor={'pointer'}
            onClick={() => previousStep()}
            _hover={{
              color: 'green.500',
            }}
          >
            <FontAwesomeIcon icon={['fas', 'arrow-left']} />
            <Text pl={1} fontWeight={700}>
              Back
            </Text>
          </Flex>
        )}
      </Flex>

      <Heading pb={4} as={'h1'} size={'lg'} color={'white'}>
        {step?.title}
      </Heading>
      <Text color={'gray.500'}>{step?.text}</Text>
    </Box>
  );
};

const Footer = () => {
  const { nextStep, isLastStep } = useWizard();
  const { user } = useAuth0();
  const history = useHistory();
  const onboarding = useOnboarding();
  const handleError = useErrorHandler();
  const { mutateAsync, isLoading } = useSaveProfile();

  const handleSave = useCallback(async () => {
    try {
      await mutateAsync({
        data: { preferences: onboarding?.state, isOnboarded: true },
        email: user.email,
      });

      history.push('/dashboard');
    } catch (err) {
      handleError(err);
    }
  }, [onboarding?.state, user.email, handleError, mutateAsync, history]);

  const handleNextStep = useCallback(() => nextStep(), [nextStep]);

  const handlerFunc = useCallback(() => {
    return isLastStep ? handleSave() : handleNextStep();
  }, [isLastStep, handleSave, handleNextStep]);

  return (
    <Box pt={6} textAlign={'right'}>
      <Button
        onClick={handlerFunc}
        label={isLastStep ? 'Done' : 'Next'}
        colorScheme={'pink'}
        size="md"
        isLoading={isLoading}
        isDisabled={isLoading}
        loadingText={'Saving...'}
      />
    </Box>
  );
};

export default Onboarding;
