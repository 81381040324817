import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Link,
  Text,
  Skeleton,
  useMediaQuery,
  useColorModeValue,
} from '@chakra-ui/react';

export const useFontSizes = () => {
  //xs
  const [isLargerThan0] = useMediaQuery('(min-width: 0px)');
  //sm
  const [isLargerThan576] = useMediaQuery('(min-width: 576px)');
  //md
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  //lg
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)');

  const fontSize = React.useMemo(() => {
    if (isLargerThan0 && !isLargerThan576) {
      return 'xs';
    } else if (isLargerThan576 && !isLargerThan768) {
      return 'sm';
    } else if (isLargerThan768 && !isLargerThan992) {
      return 'md';
    } else if (isLargerThan992) {
      return 'lg';
    }
  }, [isLargerThan0, isLargerThan576, isLargerThan768, isLargerThan992]);

  const headingFontSize = React.useMemo(() => {
    if (isLargerThan0 && !isLargerThan576) {
      return 'md';
    } else if (isLargerThan576 && !isLargerThan768) {
      return 'lg';
    } else if (isLargerThan768 && !isLargerThan992) {
      return 'xl';
    } else if (isLargerThan992) {
      return 'xl';
    }
  }, [isLargerThan0, isLargerThan576, isLargerThan768, isLargerThan992]);

  return {
    fontSize,
    headingFontSize,
  };
};
