import React from 'react';
import { RecoilRoot } from 'recoil';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClientProvider, QueryClient } from 'react-query';

import theme from 'theme';

import AuthProvider from './auth-provider';
import ApiProvider from 'providers/api-provider';
import ElementsProvider from 'providers/elements-provider';
import { NewProjectProvider } from './new-project-context';

const queryClient = new QueryClient();

const Providers = ({ children }) => (
  <RecoilRoot>
    <NewProjectProvider>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <AuthProvider>
            <ApiProvider>
              <ElementsProvider>{children}</ElementsProvider>
            </ApiProvider>
          </AuthProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </NewProjectProvider>
  </RecoilRoot>
);

export default Providers;
