import React from 'react';
import {
  Container as ChakraContainer,
  Heading,
  Text,
  Box,
  Flex,
  Badge,
} from '@chakra-ui/react';

export const GradientText = ({ children }) => (
  <Text
    as="span"
    sx={{
      bg: 'linear-gradient(291.95deg, #E952D7 0.39%, #58F6E6 50.36%, #648CFD 99.68%)',
      bgClip: 'text',
      textFillColor: 'transparent',
    }}
  >
    {children}
  </Text>
);

export const Title = ({ children, ...props }) => (
  <Heading as="h1" fontSize={['2xl', '3xl', null, '7xl']} {...props}>
    {children}
  </Heading>
);

export const Container = ({ children, ...props }) => (
  <ChakraContainer maxW="container.xl" {...props}>
    {children}
  </ChakraContainer>
);

export const Section = ({ ...props }) => (
  <Box
    mb={12}
    p={[6, null, null, 12, 12]}
    border="2px solid #1A1C23"
    backgroundColor="#262730"
    borderRadius={'3xl'}
    {...props}
  />
);

export const ComingSoon = ({ ...props }) => (
  <Flex mb={6} justify="flex-end">
    <Badge
      py={3}
      px={6}
      borderRadius="full"
      bg="purple.500"
      color="white"
      {...props}
    >
      Coming Soon
    </Badge>
  </Flex>
);
