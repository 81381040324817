import React, { useState, useEffect } from 'react';
import { SimpleGrid, Box, useDisclosure } from '@chakra-ui/react';

import { Container, Header, HeaderTitle, Section } from 'layouts/shared';
import { Block, Button, PriceCard } from 'components';
import { plans } from 'utils/plans';
import useProfile from 'hooks/use-profile';
import ChangePlanConfirmModal from './change-plan-confirm-modal';

const paidPlans = Object.values(plans).filter((plan) => {
  return plan.name.includes('Basic') || plan.name.includes('Pro');
});

const ChangePlan = () => {
  const [plan, setPlan] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: profile } = useProfile();

  useEffect(() => {
    if (!profile) return;

    setPlan(profile?.currentAccount?.plan);
  }, [profile]);

  const handlePlanClick = (value) => (event) => {
    setPlan(value);
  };

  return (
    <Container>
      <Header>
        <HeaderTitle size={'lg'} fontWeight={700}>
          Change Clippy plan
        </HeaderTitle>
      </Header>

      <Section>
        <Block pt={4}>
          <SimpleGrid
            columns={[1, 2, undefined, undefined, undefined]}
            spacing={2}
          >
            {paidPlans.map((plan) => (
              <PriceCard
                key={plan.name}
                {...plan}
                currentProductId={profile?.stripeProductId}
                currentPriceId={profile?.stripePriceId}
                buttonComponent={
                  <Button
                    mt={'auto'}
                    label={ButtonLabel({
                      monthlyPriceId: plan.monthlyPriceId,
                      currentPriceId: profile?.stripePriceId,
                    })}
                    colorScheme={plan.isMostPopular ? 'pink' : 'purple'}
                    size={'md'}
                    isDisabled={plan.monthlyPriceId === profile?.stripePriceId}
                    onClick={handlePlanClick(plan)}
                  />
                }
              />
            ))}
          </SimpleGrid>
        </Block>
        <Box textAlign={'right'}>
          <Button
            type={'submit'}
            label={'Continue'}
            colorScheme={'pink'}
            size="md"
            isDisabled={plan?.monthlyPriceId === profile?.stripePriceId}
            onClick={onOpen}
          />
        </Box>
        <ChangePlanConfirmModal
          oldPlan={profile?.currentAccount?.plan}
          newPlan={plan}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      </Section>
    </Container>
  );
};

const ButtonLabel = ({ monthlyPriceId, currentPriceId }) => {
  if (monthlyPriceId === currentPriceId) return 'Current Plan';

  return 'Choose Plan';
};

export default React.memo(ChangePlan);
