import React from 'react';
import { Controller } from 'react-hook-form';
import { Input as ChakraInput } from '@chakra-ui/react';

const Input = ({
  control,
  name,
  rules = {},
  placeholder = '',
  variant = 'outline',
  size = 'md',
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <ChakraInput
          {...field}
          placeholder={placeholder}
          size={size}
          {...rest}
        />
      )}
    />
  );
};

export default Input;
