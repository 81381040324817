import React, { createContext } from 'react';
export const NewProjectContext = createContext();

export function NewProjectProvider({ children }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <NewProjectContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </NewProjectContext.Provider>
  );
}
