import React from 'react';
import { Controller } from 'react-hook-form';
import { Switch as ChakraSwitch } from '@chakra-ui/react';

const Switch = ({
  control,
  name,
  rules = {},
  colorScheme = 'purple',
  size = 'lg',
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <ChakraSwitch
          {...field}
          id={name}
          size={size}
          colorScheme={colorScheme}
          {...rest}
        />
      )}
    />
  );
};

export default Switch;
