import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { createContext } from 'react';

export const ApiContext = createContext();

export const ApiContextProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const _axios = axios.create({
    baseURL: process.env.REACT_APP_EYECAST_API_URL,
  });

  _axios.interceptors.request.use(async (req) => {
    const token = await getAccessTokenSilently();
    req.headers.authorization = `Bearer ${token}`;
    return req;
  });

  return <ApiContext.Provider value={_axios}>{children}</ApiContext.Provider>;
};

export default ApiContextProvider;
