import React from 'react';

export const HOME_NAV_ITEMS = [
  {
    id: 'dashboard',
    path: '/dashboard',
    name: 'Home',
    icon: 'home',
  },
  {
    id: 'projects',
    path: '/projects',
    name: 'All your projects',
    icon: 'video',
  },
  {
    id: 'discover',
    path: '/discover',
    name: 'Discover',
    icon: 'play',
  },
  {
    id: 'library',
    path: '/library',
    name: 'Library',
    icon: 'photo-video',
  },
  {
    id: 'trash',
    path: '/trash',
    name: 'Trash',
    icon: 'trash',
  },
];

export const ACOUNT_NAV_ITEMS = [
  {
    id: 'dashboard',
    path: '/dashboard',
    name: 'Home',
    icon: 'home',
  },
  {
    id: 'account-settings',
    path: '/account/settings',
    name: 'My account',
    icon: 'user-circle',
    main: React.lazy(() => import('views/account-settings')),
  },
  {
    id: 'account-security',
    path: '/account/security',
    name: 'Login & Security',
    icon: 'lock',
    main: React.lazy(() => import('views/account-security')),
  },
  {
    id: 'account-notifications',
    path: '/account/notifications',
    name: 'Notifications',
    icon: 'bell',
    main: React.lazy(() => import('views/account-notifications')),
  },
  {
    id: 'account-billing',
    path: '/account/billing',
    name: 'Billing',
    icon: 'credit-card',
    main: React.lazy(() => import('views/account-billing')),
  },
];

export const HOMEPAGE_NAV_ITEMS = [
  {
    id: 'homepage-about',
    path: '/about',
    name: 'About',
    icon: 'user-circle',
    variant: 'ghost-primary',
    main: React.lazy(() => import('views/coming-soon')),
  },
  {
    id: 'homepage-faq',
    path: '/faq',
    name: 'FAQ',
    icon: 'lock',
    variant: 'ghost-primary',
    main: React.lazy(() => import('views/account-security')),
  },
];
