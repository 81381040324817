import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Onboarding from 'views/onboarding';
import useProfile from 'hooks/use-profile';

const OnboardingRedirect = ({ children }) => {
  const [isOnboarded, setOnboarded] = useState(false);

  const history = useHistory();
  const { isLoading, data } = useProfile();

  useEffect(() => {
    if (!data) return;

    if (data?.isOnboarded) setOnboarded(true);
    else history.push('/onboarding');
  }, [data, history]);

  if (isLoading) return children;

  return isOnboarded ? children : <Onboarding />;
};

export default React.memo(OnboardingRedirect);
