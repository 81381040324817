import React from 'react';
import { Container, Box, Flex, Button, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import logo from 'assets/images/clippy-white-logo.svg';
import { Title } from '../shared';

const SignupHero = () => {
  return (
    <Box bg="linear-gradient(291.95deg, #E952D7 0.39%, #58F6E6 50.36%, #648CFD 99.68%)">
      <Container maxW="container.xl" centerContent>
        <Flex
          py={[8, null, null, 24]}
          direction="column"
          align="center"
          textAlign="center"
          sx={{
            '> *': {
              mb: 10,
            },
          }}
        >
          <Box
            flex
            align="center"
            boxSize={['50%', '70%', '70%', '70%']}
            objectFit="cover"
          >
            <Image src={logo} alt={'Eyecase'} />
          </Box>

          <Title>
            Start telling your
            <br /> story today
          </Title>
          <Link to="/dashboard">
            <Button colorScheme="whiteAlpha" size="lg">
              Try Clippy for free
            </Button>
          </Link>
        </Flex>
      </Container>
    </Box>
  );
};

export default SignupHero;
