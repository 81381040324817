import React from 'react';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

import { Container, Title, GradientText } from '../shared';

const Faq = ({ data }) => {
  return (
    <Box backgroundColor="#000">
      <Container maxW="container.xl" centerContent>
        <Box py={[6, null, null, 12, 12]} width="100%">
          <Title mb={12} textAlign="center">
            Frequently Asked <GradientText> Questions</GradientText>
          </Title>

          <Accordion>
            {data.map(({ title, description }, index) => (
              <AccordionItem
                key={index}
                mb={8}
                bg={'#262730'}
                color={'#B4C1CB'}
                border={0}
                borderRadius={'3xl'}
              >
                <h2>
                  <AccordionButton py={6} _focus={{ boxShadow: 'none' }}>
                    <Box flex="1" textAlign="left" fontWeight={'600'}>
                      {title}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>{description}</AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
};

export default Faq;
