import { createContext, useContext, useReducer, useMemo } from 'react';

const OnboardingContext = createContext();

export const OnboardingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {});

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'update': {
      return { ...state, ...action.data };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const useOnboarding = () => {
  const context = useContext(OnboardingContext);

  if (context === undefined) {
    throw new Error('useOnboarding must be used within a OnboardingProvider');
  }

  return context;
};

export default OnboardingProvider;
export { useOnboarding };
