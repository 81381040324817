import React from 'react';
import { Box, Flex, Heading, Skeleton } from '@chakra-ui/react';
import Project from '../../dashboard/components/project';
import { useAuth0 } from '@auth0/auth0-react';
import { SizeChangerPagination } from 'components';
import constants from 'constants';
import { isEmpty, map, size, filter } from 'lodash';
import { getFriendlyTime } from '../../../utils/date';
import userProjectsHook from 'hooks/use-projects';
import { LoadingView } from 'components';

const imageUrls = [
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-001.jpg',
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-002.jpg',
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-003.jpg',
  'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-004.jpg',
];

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export const UserProjects = () => {
  const { user } = useAuth0();
  const { allProjects, isLoading } = userProjectsHook();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(10);

  const startIndex = (currentPage - 1) * pageLimit;
  const endIndex = startIndex + pageLimit;

  const currentPageProjects = React.useMemo(() => {
    const modifiedProj = map(
      filter(allProjects, (f) => f.status === constants.status.ACTIVE),
      (proj) => proj
    );
    return modifiedProj.length > 0
      ? modifiedProj.slice(startIndex, endIndex)
      : [];
  }, [allProjects, startIndex, endIndex]);

  const totalPages = !isEmpty(allProjects)
    ? Math.ceil(size(allProjects) / pageLimit)
    : 0;
  const hasPrevPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;

  const handleOnProjectClick = (id) => {
    window.location.replace(`https://app.clippy.io/canvas/${id}`);
  };

  return (
    <>
      <Box>
        <Flex mb={8} alignItems={'center'} justifyContent={'space-between'}>
          <Heading as={'h1'} fontWeight={700} size={'xl'}>
            My projects
          </Heading>
        </Flex>
        {isLoading && <LoadingView />}
        <Skeleton isLoaded={!isLoading} display={'grid'}>
          <Box
            display={'grid'}
            gridTemplateColumns={[
              'repeat(auto-fill, minmax(100%, 1fr))',
              'repeat(auto-fill, minmax(30%, 1fr))',
              'repeat(auto-fill, minmax(30%, 1fr))',
              'repeat(auto-fill, minmax(30%, 1fr))',
              'repeat(auto-fill, minmax(15%, 1fr))',
            ]}
            gridGap={'1.5rem'}
            minHeight="300px"
          >
            {!isLoading &&
              map(currentPageProjects, (proj, index) => {
                return (
                  <Project
                    key={index}
                    projectId={proj.id}
                    onClick={() => handleOnProjectClick(proj.id)}
                    coverPhoto={
                      proj.albumCoverUrl || imageUrls[getRandomInt(3)]
                    }
                    title={proj.title || `project 0${index}`}
                    updatedAt={getFriendlyTime(proj.updatedAt)}
                    nickname={user.nickname}
                  />
                );
              })}
          </Box>
        </Skeleton>
      </Box>
      {!isEmpty(currentPageProjects) && (
        <SizeChangerPagination
          totalPages={totalPages}
          currentPage={currentPage}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
          itemsPerPage={pageLimit}
          onClick={(val) => setCurrentPage(val)}
          pageLimit={pageLimit}
          setPageLimit={(val) => {
            setPageLimit(val);
          }}
        />
      )}
    </>
  );
};
