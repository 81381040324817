import React from 'react';
import { Box, Flex, Button } from '@chakra-ui/react';

export const DropdownContainer = React.forwardRef(
  ({ children, ...rest }, ref: null) => (
    <Box
      ref={ref}
      borderRadius={'0.25rem'}
      boxShadow={
        '0 0 0 1px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.15)'
      }
      backgroundColor={'#262730'}
      color={'white'}
      {...rest}
    >
      {children}
    </Box>
  )
);

export const DropdownContent = ({ children, ...rest }) => (
  <Flex
    height={'100%'}
    justifyContent={'center'}
    alignItems={'center'}
    fontFamily={'initial'}
    textAlign={'center'}
    {...rest}
  >
    {children}
  </Flex>
);

export const DropdownNav = ({ children, ...rest }) => (
  <Flex as={'nav'} flexDirection={'column'} {...rest}>
    {children}
  </Flex>
);

export const DropdownNavItem = ({ children, onClick, ...rest }) => (
  <Button
    onClick={onClick}
    backgroundColor={'transparent'}
    rounded={false}
    justifyContent={'flex-start'}
    _hover={{
      bg: 'blue.500',
    }}
    {...rest}
  >
    {children}
  </Button>
);

export const DropdownNavItemIcon = ({ children, ...rest }) => (
  <Box as={'span'} pr={'1rem'} {...rest}>
    {children}
  </Box>
);

export const DropdownNavItemContent = ({ children, ...rest }) => (
  <Box {...rest}>{children}</Box>
);
