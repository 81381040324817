const config = {
  app: {
    name: 'Eyecast',
    domain: process.env.REACT_APP_URL,
    logoutUrl: 'https://www.clippy.io',
  },
  auth: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    managementApiEndpoint: process.env.REACT_APP_AUTH0_MANAGEMENT_API_URL,
    audience: 'https://dev-api.eyecast.com',
    claims: {
      loginsCount: 'https://eyecast.com/loginsCount',
      lastPasswordReset: 'https://eyecast.com/lastPasswordReset',
    },
  },
  stripe: {
    publishableKey: `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`,
  },
};

export default config;
