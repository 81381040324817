import React from 'react';
import { BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';

import HomepageLayout from 'layouts/homepage';
import DefaultLayout from 'layouts/default';
import OnboardingLayout from 'layouts/onboarding';
import PublicRoute from './components/public-route';
import ProtectedRoute from './components/protected-route';
import ModalRoute from './components/modal-route';
import { HOME_NAV_ITEMS, ACOUNT_NAV_ITEMS } from './nav-items';

import Homepage from 'views/homepage';
import Dashboard from 'views/dashboard';
import { AllProjects } from 'views/projects';
import { AllLibraryProjects } from 'views/librarypage';
import { AllTrashProjects } from 'views/trash-projects';
import AccountSettings from 'views/account-settings';
import AccountSecurity from 'views/account-security';
import AccountNotifications from 'views/account-notifications';
import AccountBilling from 'views/account-billing';
import Pricing from 'views/pricing';
import Onboarding from 'views/onboarding';
import Signup from 'views/signup';
import PaymentMethodNew from 'views/payment-method-new';
import ChangePlan from 'views/change-plan';
import comingSoon from 'views/coming-soon';

const Routes = ({ children }) => (
  <Router>
    <RoutesSwitch />
  </Router>
);

const RoutesSwitch = () => {
  let location = useLocation();
  let background = location.state && location.state.background;

  return (
    <React.Fragment>
      <Switch location={background || location}>
        <PublicRoute
          exact
          path={'/'}
          component={Homepage}
          layout={HomepageLayout}
          routes={[]}
        />
        <ProtectedRoute
          exact
          path="/about"
          component={comingSoon}
          layout={HomepageLayout}
          routes={[]}
        />
        <ProtectedRoute
          exact
          path="/contact"
          component={comingSoon}
          layout={HomepageLayout}
          routes={[]}
        />
        <ProtectedRoute
          exact
          path="/discover"
          component={comingSoon}
          layout={HomepageLayout}
          routes={[]}
        />{' '}
        <ProtectedRoute
          exact
          path="/trash"
          component={AllTrashProjects}
          layout={DefaultLayout}
          routes={HOME_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/faq"
          component={comingSoon}
          layout={HomepageLayout}
          routes={[]}
        />
        <ProtectedRoute
          exact
          path="/dashboard"
          component={Dashboard}
          layout={DefaultLayout}
          routes={HOME_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/projects"
          component={AllProjects}
          layout={DefaultLayout}
          routes={HOME_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/library"
          component={AllLibraryProjects}
          layout={DefaultLayout}
          routes={HOME_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/account/settings"
          component={AccountSettings}
          layout={DefaultLayout}
          routes={ACOUNT_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/account/security"
          component={AccountSecurity}
          layout={DefaultLayout}
          routes={ACOUNT_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/account/notifications"
          component={AccountNotifications}
          layout={DefaultLayout}
          routes={ACOUNT_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/account/billing"
          component={AccountBilling}
          layout={DefaultLayout}
          routes={ACOUNT_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/onboarding"
          component={Onboarding}
          layout={OnboardingLayout}
        />
        <ProtectedRoute
          exact
          path="/signup/:plan"
          component={Signup}
          layout={DefaultLayout}
          routes={ACOUNT_NAV_ITEMS}
        />
        <ProtectedRoute
          exact
          path="/change-plan"
          component={ChangePlan}
          layout={DefaultLayout}
          routes={ACOUNT_NAV_ITEMS}
        />
        <PublicRoute
          exact
          path={'/privacy'}
          component={comingSoon}
          layout={HomepageLayout}
          routes={[]}
        />
        <PublicRoute
          exact
          path={'/help'}
          component={comingSoon}
          layout={HomepageLayout}
          routes={[]}
        />
      </Switch>
      {background && (
        <React.Fragment>
          <ModalRoute
            exact
            path="/pricing"
            component={Pricing}
            size={'1000px'}
          />
          <ModalRoute
            exact
            path="/payment-method/new"
            component={PaymentMethodNew}
            size={'md'}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Routes;
export { HOME_NAV_ITEMS, ACOUNT_NAV_ITEMS };
