import React from 'react';
import {
  chakra,
  Flex,
  // useColorModeValue,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from '@chakra-ui/react';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
// import { HiDotsHorizontal } from 'react-icons/hi';
import {
  // ArrowLeftIcon,
  // ArrowRightIcon,
  ChevronDownIcon,
} from '@chakra-ui/icons';

export const SizeChangerPagination = ({
  totalPages = 0,
  currentPage = 1,
  hasPrevPage,
  hasNextPage,
  onClick = (f) => f,
  pageLimit = 10,
  setPageLimit,
}) => {
  const siblingCount = 1;

  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const paginationRange = React.useMemo(() => {
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPages) {
      return range(1, totalPages);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, 'DOTS', totalPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(totalPages - rightItemCount + 1, totalPages);
      return [firstPageIndex, 'DOTS', ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, 'DOTS', ...middleRange, 'DOTS', lastPageIndex];
    }
  }, [totalPages, siblingCount, currentPage]);

  const handleOnButtonClick = (val) => {
    onClick(val);
  };

  const handleOnPrevClick = () => {
    onClick(currentPage - 1);
  };

  const handleOnNextClick = () => {
    onClick(currentPage + 1);
  };

  const handleOnProjectLimit = (val) => {
    setPageLimit(Number(val));
  };

  const PagButton = ({ value, onClick, ...props }) => {
    const handleOnBtnClick = () => {
      onClick(value);
    };

    const disabled = props.disabled;

    const activeStyle = {
      bg: '#17161D',
      _dark: { bg: '#17161D' },
      color: 'white',
    };
    return (
      <chakra.button
        mx={1}
        px={4}
        py={2}
        rounded="md"
        bg="whiteAlpha.300"
        _dark={{ bg: 'gray.800' }}
        color="gray.700"
        opacity={props.disabled && 0.6}
        _hover={!props.disabled && activeStyle}
        cursor={props.disabled && 'not-allowed'}
        disabled={props.disabled}
        {...(props.active && activeStyle)}
        {...(!disabled && { onClick: handleOnBtnClick })}
      >
        {props.children}
      </chakra.button>
    );
  };
  // const MButton = (props) => {
  //   const DoubleArrow = props.left ? ArrowLeftIcon : ArrowRightIcon;
  //   const [hovered, setHovered] = React.useState(false);
  //   const color1 = useColorModeValue('brand.800', 'brand.700');
  //   const color2 = useColorModeValue('gray.100', 'gray.200');

  //   return (
  //     <chakra.a
  //       w={8}
  //       py={2}
  //       color="gray.700"
  //       _dark={{ color: 'gray.200' }}
  //       onMouseOver={() => setHovered(true)}
  //       onMouseOut={() => setHovered(false)}
  //       cursor="pointer"
  //       textAlign="center"
  //     >
  //       {hovered ? (
  //         <Icon as={DoubleArrow} boxSize={3} cursor="pointer" color={color1} />
  //       ) : (
  //         <Icon
  //           as={HiDotsHorizontal}
  //           color={color2}
  //           boxSize={4}
  //           opacity={0.5}
  //         />
  //       )}
  //     </chakra.a>
  //   );
  // };

  return (
    <Flex p={50} w="full" alignItems="center" justifyContent="center">
      <Flex>
        <PagButton
          value={currentPage}
          onClick={handleOnPrevClick}
          data-id="hasPrevPage"
          disabled={!hasPrevPage}
        >
          <Icon
            as={IoIosArrowBack}
            color="gray.700"
            _dark={{ color: 'gray.200' }}
            boxSize={4}
          />
        </PagButton>
        {paginationRange &&
          paginationRange.map((page, index) => (
            <PagButton
              key={index}
              value={page === 'DOTS' ? paginationRange[index - 1] + 1 : page}
              onClick={handleOnButtonClick}
              active={page === currentPage}
            >
              {page !== 'DOTS' ? page : '...'}
            </PagButton>
          ))}
        <PagButton
          value={currentPage}
          onClick={handleOnNextClick}
          disabled={!hasNextPage}
          data-id="hasNextPage"
        >
          <Icon
            as={IoIosArrowForward}
            color="gray.700"
            _dark={{ color: 'gray.200' }}
            boxSize={4}
          />
        </PagButton>
        <Menu
          bgColor="shark"
          variant="outline"
          _hover={{
            backgroundColor: 'charade',
          }}
          _activeLink={{
            backgroundColor: 'charade',
            fontWeight: '700',
          }}
          border="1px solid shark"
        >
          <MenuButton
            bg="whiteAlpha.300"
            ml={1}
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            {pageLimit} / Page
          </MenuButton>
          <MenuList bgColor="shark">
            <MenuItem
              value="10"
              onClick={(e) => {
                handleOnProjectLimit(e.target.value);
              }}
            >
              10 / page
            </MenuItem>
            <MenuItem
              value="20"
              onClick={(e) => {
                handleOnProjectLimit(e.target.value);
              }}
            >
              20 / page
            </MenuItem>
            <MenuItem
              value="30"
              onClick={(e) => {
                handleOnProjectLimit(e.target.value);
              }}
            >
              30 / page
            </MenuItem>
            <MenuItem
              value="40"
              onClick={(e) => {
                handleOnProjectLimit(e.target.value);
              }}
            >
              40 / page
            </MenuItem>
            <MenuItem
              value="50"
              onClick={(e) => {
                handleOnProjectLimit(e.target.value);
              }}
            >
              50 / page
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};
