import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

const ReactSelect = ({
  component: Component = Select,
  control,
  name,
  options = [],
  rules = {},
  placeholder = '',
  onChange: handleChange = () => null,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, ...fieldRest } }) => (
        <Component
          {...fieldRest}
          onChange={(event, action) => {
            onChange(event);
            handleChange(event, action);
          }}
          options={options}
          placeholder={placeholder}
          styles={customStyles}
          {...rest}
        />
      )}
    />
  );
};

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    height: '40px',
  }),
  control: (provided, state) => ({
    ...provided,
    height: '40px',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    borderRadius: '0.375rem',
    borderColor: '#2D3748',
    ':hover': {
      border: '1px solid blue.500',
      borderColor: 'blue.500',
      backgroundColor: 'transparent',
    },
    ...(state.isFocused && { border: '1px solid blue.500' }),
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: 'inherit',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: '#2D3748',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#2D3748',
  }),
  option: (provided, state) => ({
    ...provided,
    color: '#2D3748',
    fontSize: '0.9rem',
    ':hover': {
      borderColor: 'transparent',
      backgroundColor: '#718096',
      color: 'white',
    },
    ...(state.isFocused && { backgroundColor: 'white' }),
    ...(state.isSelected && { backgroundColor: 'white', color: '#2D3748' }),
  }),
  multiValueRemove: (styles, state) => ({
    ...styles,
    color: '#2D3748',
    ':hover': {
      backgroundColor: '#718096',
      color: 'white',
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
};

export default ReactSelect;
