import React from 'react';
import { SimpleGrid, useCheckboxGroup } from '@chakra-ui/react';

import { useOnboarding } from '../provider';
import CheckboxCard from './checkbox-card';

const options = [
  {
    value: 'Never edited',
    src: '👋',
  },
  {
    value: 'Basic experience',
    src: '😇',
  },
  {
    value: 'Intermediate experience',
    src: '🤘',
  },
  {
    value: 'Advanced video editor',
    src: '😎',
  },
];

const StepOne = () => {
  const onboarding = useOnboarding();

  const { getCheckboxProps } = useCheckboxGroup({
    name: 'experience',
    defaultValue: onboarding?.state?.experience,
    onChange: (val) =>
      onboarding.dispatch({ type: 'update', data: { experience: val } }),
  });

  return (
    <SimpleGrid minChildWidth={'18rem'} spacing={5}>
      {options.map(({ src, value }) => {
        const checkbox = getCheckboxProps({ value });

        return (
          <CheckboxCard key={value} {...checkbox} src={src}>
            {value}
          </CheckboxCard>
        );
      })}
    </SimpleGrid>
  );
};

export default React.memo(StepOne);
