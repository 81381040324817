import React from 'react';
import { Box } from '@chakra-ui/react';

const Block = ({ children, ...props }) => (
  <Box
    borderBottomStyle={'solid'}
    borderBottomWidth={'thin'}
    borderBottomColor={'charadeDark6'}
    mb={6}
    pb={6}
    {...props}
  >
    {children}
  </Box>
);

export default Block;
