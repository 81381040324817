import '@fontsource/inter';
import React from 'react';
import ReactDOM from 'react-dom/client';
/// deploy
import reportWebVitals from './reportWebVitals';
import Providers from 'providers';
import initFonts from 'utils/fonts';
import * as Sentry from '@sentry/react';
import App from 'app';
import './app.css';

initFonts();

Sentry.init({
  dsn: 'https://4a1c98a70c94569235dd7e8cef7e2647@o4508231082442752.ingest.us.sentry.io/4508231083950080',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/clippy\.io\/api/,
    /^https:\/\/app\.clippy\.io\/canvas/,
    /^https:\/\/www\.clippy\.io\/dashboard/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <Providers>
    <App />
  </Providers>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
