import { Box, Text } from '@chakra-ui/react';
import React from 'react';

const BoxWrapper = ({ children, text, caption, active, onClick }) => {
  let activeProps = {};
  let mainBoxProps = {};
  if (active) {
    activeProps = {
      borderRadius: 0.25,
      borderColor: 'azure',
      border: '1px solid var(--chakra-colors-azure)',
      borderStyle: 'dashed',
    };

    mainBoxProps = {
      borderRadius: 0.25,
      border: '1px solid var(--chakra-colors-azure)',
    };
  }

  const handleOnClick = () => {
    return onClick(text);
  };
  return (
    <Box {...activeProps}>
      <Box
        as="button"
        type="button"
        w="159px"
        h="179px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        boxSizing="border-box"
        onClick={handleOnClick}
        {...mainBoxProps}
      >
        {children}
        <Text fontSize="sm" mt={2} mb={2}>
          {text}
        </Text>
        <Text fontSize="xs">{caption || ''}</Text>
      </Box>
    </Box>
  );
};

export default BoxWrapper;
