import React from 'react';
import { Link } from 'react-router-dom';
import {
  Flex,
  Box,
  Badge,
  Heading,
  Text,
  List,
  ListItem,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from 'components';

const PricingCard = ({
  name,
  description,
  features,
  monthlyAmount,
  productId,
  monthlyPriceId,
  isMostPopular,
  isFree,
  contactUs,
  path,
  currentProductId,
  currentPriceId,
  buttonComponent = null,
}) => (
  <Flex
    direction={'column'}
    borderWidth={'2px'}
    borderStyle={'solid'}
    borderColor={'gray.700'}
    borderRadius={'md'}
    padding={10}
    minH={'35rem'}
    position={'relative'}
  >
    {isMostPopular && (
      <Box
        position={'absolute'}
        top={'-18px'}
        left={'50%'}
        zIndex={1}
        transform={'translateX(-50%)'}
      >
        <Badge p={2} colorScheme="pink" variant="solid">
          BEST VALUE
        </Badge>
      </Box>
    )}

    <Heading
      mb={4}
      as={'h3'}
      size={'lg'}
      fontWeight={700}
      textAlign={'center'}
      color={'white'}
    >
      {name}
    </Heading>
    <Text color={'white'} textAlign={'center'}>
      {description}
    </Text>
    <Text
      py={10}
      textAlign={'center'}
      color={'white'}
      fontSize={'2xl'}
      fontWeight={700}
    >
      {contactUs ? 'Contact Sales' : `A${monthlyAmount} /month`}
    </Text>
    <List pb={10} spacing={3} color={'white'}>
      {features.map((feature) => (
        <ListItem key={feature}>
          <Box
            as={'span'}
            mr={4}
            color={isMostPopular ? 'pink.500' : 'blue.500'}
          >
            <FontAwesomeIcon icon={['fas', 'check-circle']} />
          </Box>
          {feature}
        </ListItem>
      ))}
    </List>
    {buttonComponent ? (
      React.cloneElement(buttonComponent)
    ) : (
      <Button
        mt={'auto'}
        as={Link}
        to={path}
        label={ButtonLabel({
          isFree,
          contactUs,
          monthlyPriceId,
          currentPriceId,
        })}
        colorScheme={isMostPopular ? 'pink' : 'purple'}
        size={'md'}
        isDisabled={monthlyPriceId === currentPriceId}
      />
    )}
  </Flex>
);

const ButtonLabel = ({ isFree, contactUs, monthlyPriceId, currentPriceId }) => {
  if (contactUs) return 'Contact Sales';
  if (isFree) return 'Get Started';
  if (monthlyPriceId === currentPriceId) return 'Current Plan';

  return 'Choose Plan';
};

export default React.memo(PricingCard);
