import React, { useState } from 'react';
import { usePopper } from 'react-popper';

import { Portal } from 'components';

const Popover = ({
  active = true,
  baseElement,
  children,
  offset = [0, 0],
  placement = 'auto',
  fallbackPlacements = ['auto'],
  zIndex,
  ...rest
}) => {
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(baseElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements,
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 0,
        },
      },
    ],
  });

  return (
    <Portal>
      {active && (
        <div
          ref={setPopperElement}
          style={{ zIndex, ...styles.popper }}
          {...attributes.popper}
          {...rest}
        >
          {children}
        </div>
      )}
    </Portal>
  );
};

export default Popover;
