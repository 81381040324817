import { extendTheme } from '@chakra-ui/react';

// Global style overrides
import styles from './styles';

// Component style overrides
import Button from './components/button';
import Input from './components/input';
import Switch from './components/switch';
import Link from './components/link';
import Modal from './components/modal';
import Menu from './components/menu';
import MenuButton from './components/menu-button';

const overrides = {
  ...styles,
  components: {
    Button,
    Input,
    Switch,
    Link,
    Modal,
    Menu,
    MenuButton,
  },
};

export default extendTheme(overrides);
