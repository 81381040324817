import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Routes from 'routes';
import { LoadingSpinner } from 'components';
import { Center, VStack } from '@chakra-ui/react';
import spinnerAnimationData from './assets/images/clippy-spinner.json';

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    // refreshPage();
    // return <div>Oops... {error.message}</div>;
    console.log('oops. invalidate state error', error.message);
    window.location.href = 'https://www.clippy.io/';
  }

  if (isLoading) {
    return (
      <VStack
        spacing={4}
        flex={1}
        bgColor={'shark'}
        height={'100vh'}
        align={'center'}
        justifyContent={'center'}
      >
        <Center w="100px" h="100px">
          <LoadingSpinner
            animationData={spinnerAnimationData}
            width={100}
            height={100}
          />
        </Center>
      </VStack>
    );
  }

  return <Routes />;
};

export default App;

// deploy app
