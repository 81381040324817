import React from 'react';
import {
  Flex,
  Image,
  Link,
  Box,
  useDisclosure,
  Show,
  Hide,
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from 'assets/images/clippy-logo.svg';

import MobileNav from './mobile-nav';

const Header = ({ children, redirectPath = '/', ...props }) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Flex
      px={10}
      h={'4.5rem'}
      alignItems="center"
      justifyContent="space-between"
      borderBottom={'2px solid transparent'}
      {...props}
      sx={{
        borderImage:
          'linear-gradient(291.95deg, #E952D7 0.39%, #58F6E6 50.36%, #648CFD 99.68%)',
        borderImageSlice: '1',
        ...props?.sx,
      }}
    >
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Link as={ReactLink} to={redirectPath}>
          <Image src={logo} alt={'Eyecase'} />
        </Link>
        <Box display={['block', 'none']} onClick={() => onToggle()}>
          <FontAwesomeIcon icon={['fa', `${isOpen ? 'bars' : 'bars'}`]} />
        </Box>
      </Flex>

      <Flex alignItems={'center'}>
        <Show below="sm">{isOpen && <MobileNav>{children}</MobileNav>}</Show>
        <Hide below="sm">{children}</Hide>
      </Flex>
    </Flex>
  );
};

export default Header;
