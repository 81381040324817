import constants from '../../constants';

export const getCardIcon = (brand) => {
  const paymentMethods = constants.paymentMethods;

  switch (brand) {
    case paymentMethods.visa:
      return 'cc-visa';
    case paymentMethods.americanExpress:
      return 'cc-amex';
    case paymentMethods.masterCard:
      return 'cc-mastercard';
    case paymentMethods.discover:
      return 'cc-discover';
    case paymentMethods.jcd:
      return 'cc-jcb';
    case paymentMethods.dinersClub:
      return 'cc-diners-club';
    default:
      return '';
  }
};
