import { useQuery } from 'react-query';

import constants from '../constants';
import useApi from './use-api';
import { plans } from 'utils/plans';

const useProfile = () => {
  const api = useApi();

  return useQuery(
    'profile',
    () =>
      api.get(`/${constants.endpoints.me}`).then(({ data }) => {
        return computedProps({
          ...data.data,
        });
      }),
    {
      useErrorBoundary: true,
      staleTime: Infinity,
    }
  );
};

const computedProps = (data) => {
  const { stripeSubscriptionId, stripePriceId, currentPeriodEnds } = data;

  const isPayingCustomer =
    stripeSubscriptionId !== null && currentPeriodEnds > Date.now();

  const plan = Object.values(plans).find(
    (plan) => plan.monthlyPriceId === stripePriceId
  );

  return {
    isPayingCustomer,
    ...data,
    currentAccount: {
      plan,
      features: data?.features || [],
    },
  };
};

export default useProfile;
