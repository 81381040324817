import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, ErrorBoundary } from 'components';
import { getMonthName } from 'utils/date';
import usePaymentMethod from '../hooks/use-payment-method';
import { getCardIcon } from '../helpers';

const PaymentMethod = ({ paymentMethodId }) => {
  const location = useLocation();
  const { user } = useAuth0();
  const { email } = user;

  const { data, isLoading } = usePaymentMethod({ email, paymentMethodId });

  if (isLoading) return 'loading card...';

  return (
    <ErrorBoundary>
      <Box
        borderWidth={'2px'}
        borderColor={'gray.700'}
        borderStyle={'solid'}
        p={4}
        maxWidth={'31.25rem'}
        borderRadius={'md'}
        color={'gray.500'}
      >
        <Flex pb={4} alignItems={'center'}>
          <Box
            borderWidth={'2px'}
            borderColor={'gray.700'}
            borderStyle={'solid'}
            mr={4}
            p={4}
            borderRadius={'md'}
          >
            <FontAwesomeIcon
              icon={['fab', getCardIcon(data?.card?.brand)]}
              size={'2x'}
            />
          </Box>
          <Box>
            <Text fontSize={'md'} fontWeight={700}>
              {data?.card?.brand} •••• •••• •••• {data?.card?.last4}
            </Text>
          </Box>
        </Flex>
        <Text pb={4} fontSize={'sm'}>
          Expires {getMonthName(data?.card?.exp_month)} {data?.card?.exp_year}
        </Text>
        <Box textAlign={'right'}>
          <Button
            as={Link}
            to={{
              pathname: `/payment-method/new`,
              state: { background: location },
            }}
            label={'Change'}
            colorScheme={'brand.primary'}
            size="md"
          />
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default PaymentMethod;
