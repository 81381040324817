import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';

import { Container, Header, HeaderTitle, Section } from 'layouts/shared';
import { PriceCard } from 'components';
import { plans } from 'utils/plans';
import useProfile from 'hooks/use-profile';

const Pricing = () => {
  const { data: profile } = useProfile();

  return (
    <Container>
      <Header border={'none'}>
        <HeaderTitle
          size={'lg'}
          color={'white'}
          fontWeight={700}
          textAlign={'center'}
        >
          Clippy Pricing
        </HeaderTitle>
      </Header>

      <Section>
        <SimpleGrid columns={[1, 2, undefined, undefined, 4]} spacing={2}>
          {Object.values(plans).map((plan) => (
            <PriceCard
              key={plan.name}
              {...plan}
              currentProductId={profile?.stripeProductId}
              currentPriceId={profile?.stripePriceId}
            />
          ))}
        </SimpleGrid>
      </Section>
    </Container>
  );
};

export default Pricing;
