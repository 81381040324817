import React, { useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useForm } from 'react-hook-form';
import { SimpleGrid, Stack } from '@chakra-ui/react';

import { FormControl, Input, Button, Alert } from 'components';
import useSaveAuthUser from 'hooks/use-save-auth-user';

const PasswordForm = ({ isEditing, setIsEditing }) => {
  const { user } = useAuth0();
  const { mutateAsync, isLoading } = useSaveAuthUser();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    watch,
  } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
  });

  const oldPassword = useRef({});
  oldPassword.current = watch('oldPassword', '');

  const onSubmit = async (data) => {
    try {
      await mutateAsync({ id: user.sub, data: { password: data.newPassword } });
      setIsEditing.off();
    } catch (err) {
      setError('name', {
        type: 'manual',
        message: renderError(JSON.parse(err.message)),
      });
    }
  };

  const cancel = async () => {
    reset();
    setIsEditing.off();
  };

  return (
    <React.Fragment>
      {errors.name && <Alert description={errors.name.message} mb={'1rem'} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={[1, 2]} spacing={5}>
          <FormControl
            label={'Old Password'}
            errorText={errors?.oldPassword?.message}
            isInvalid={errors?.oldPassword}
          >
            <Input
              name="oldPassword"
              type={'password'}
              placeholder="Password"
              control={control}
              rules={{
                required: 'You must specify a password',
                minLength: {
                  value: 8,
                  message: 'Password must have at least 8 characters',
                },
              }}
            />
          </FormControl>
          <FormControl
            label={'New Password'}
            errorText={errors?.newPassword?.message}
            isInvalid={errors?.newPassword}
          >
            <Input
              name="newPassword"
              type={'password'}
              placeholder="New Password"
              control={control}
              rules={{
                validate: (value) =>
                  value === oldPassword.current ||
                  'The passwords do not match. Please try again',
              }}
            />
          </FormControl>
        </SimpleGrid>

        <Stack mt={6} direction="row" justifyContent={'flex-end'} spacing={3}>
          <Button
            label={'Cancel'}
            colorScheme={'brand.primary'}
            onClick={cancel}
          />
          <Button
            type={'submit'}
            label={'Update Password'}
            isLoading={isLoading}
            isDisabled={isLoading}
            loadingText={'Saving...'}
            colorScheme={'pink'}
          />
        </Stack>
      </form>
    </React.Fragment>
  );
};

const renderError = (response) => {
  switch (response.type) {
    case 'Bad Request':
      return response.message;
    default:
      return 'Sorry, we have failed you. Our team has been notified. In the meantime, please try again later or contact Compozer for help';
  }
};

export default PasswordForm;
