const MenuButton = {
  baseStyle: {
    bg: 'brand.primary.900',
    _focus: {
      boxShadow: 'none',
    },
    'menu-button': {
      bg: 'brand.primary.900',
    },
    menubutton: {
      bg: 'brand.primary.900',
    },
    button: {
      bg: 'brand.primary.900',
    },
  },
  variants: {
    'ghost-primary': {
      color: '#9393A2',
      _hover: {
        bg: '#262630',
      },
    },
  },
};

export default MenuButton;
