import {
  Button,
  Center,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import React from 'react';
import { HexColorPicker } from 'react-colorful';

const ColorPicker = ({ value = '#aabbcc', onChange, variant = 'outline' }) => {
  const color = value;

  return (
    <Flex
      display="inline-flex"
      alignItems="center"
      borderRadius={2}
      borderColor={'#3c394d'}
      borderStyle={'solid'}
    >
      <Popover variant="picker">
        <PopoverTrigger>
          <Button
            aria-label={color}
            background={color}
            height="40px"
            width="40px"
            padding={0}
            minWidth="unset"
            borderRadius={3}
            variant={variant}
            borderColor="inputBorder"
          ></Button>
        </PopoverTrigger>
        <PopoverContent width="200px">
          <PopoverArrow bg={color} />
          <PopoverCloseButton color="white" />
          <PopoverHeader
            height="60px"
            backgroundColor={color}
            borderTopLeftRadius={5}
            borderTopRightRadius={5}
            color="white"
          >
            <Center height="100%">{color}</Center>
          </PopoverHeader>
          <PopoverBody p={0}>
            <HexColorPicker color={color} onChange={onChange} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default ColorPicker;
