import { useQuery } from 'react-query';

import constants from '../../../constants';
import useApi from 'hooks/use-api';

const usePaymentMethod = ({ email, paymentMethodId }) => {
  const api = useApi();

  return useQuery(
    'paymentMethod',
    () =>
      api
        .get(
          `/${constants.endpoints.users}/${email}/${constants.endpoints.paymentMethods}/${paymentMethodId}`
        )
        .then(({ data }) => {
          return {
            ...data.data,
          };
        }),
    {
      useErrorBoundary: true,
      enabled: paymentMethodId !== undefined,
    }
  );
};

export default usePaymentMethod;
