import { useMutation, useQueryClient } from 'react-query';

import constants from '../constants';
import useApi from './use-api';

const useSaveProfile = () => {
  const queryClient = useQueryClient();
  const api = useApi();

  return useMutation(
    ({ email, data }) =>
      api
        .put(`/${constants.endpoints.users}/${email}`, data)
        .then(({ data }) => {
          return {
            ...data.data,
          };
        }),
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries('profile');
      },
    }
  );
};

export default useSaveProfile;
