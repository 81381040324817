import React, { Fragment } from 'react';

import { Container, Title, GradientText } from '../shared';

import Hero from './hero';
import Feature from './feature';
import FeatureApp from '../components/feature-app';
import Testimonials from '../components/testimonials';
import Faq from '../components/faq';
import SignupHero from '../components/signup-hero';
import Footer from '../components/footer';

import features from '../features.json';
import faqs from '../faq.json';
import testimonials from '../testimonials.json';

const Homepage = () => {
  return (
    <Fragment>
      <Hero />
      <Container py={[8, null, null, 24]}>
        <Title>
          The video editing platform
          <br />
          <GradientText> designed for everyone</GradientText>
        </Title>
      </Container>

      {features.map((item, index) => (
        <Feature
          key={index}
          data={item}
          direction={index % 2 === 0 ? 'left' : 'right'}
        />
      ))}

      <FeatureApp />
      <Testimonials data={testimonials} />
      <Faq data={faqs} />
      <SignupHero />
      <Footer />
    </Fragment>
  );
};

export default Homepage;
