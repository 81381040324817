import React from 'react';
import { Box, Text, Flex, Avatar } from '@chakra-ui/react';

import { Slider, SliderItem } from 'components';
import { Container, Title, GradientText } from '../shared';

const Testimonials = ({ data }) => {
  return (
    <Box backgroundColor="#000">
      <Container maxW="container.xl" centerContent>
        <Box py={[6, null, null, 12, 12]} width="100%">
          <Title mb={12} textAlign="center">
            <GradientText>Empowering users</GradientText> everywhere to tell
            their stories
          </Title>
          <Slider>
            {data.map(({ name, occupation, description }, index) => (
              <SliderItem key={index} minWidth="290px">
                <Box
                  border={`2px solid #535665`}
                  p="6"
                  backgroundColor="#262730"
                  borderRadius={'3xl'}
                >
                  <Text as="q" color="#B4C1CB" fontWeight="600" fontSize="2xl">
                    {description}
                  </Text>

                  <Flex mt={4} alignItems="center">
                    <Avatar
                      src="https://bit.ly/broken-link"
                      size="lg"
                      bg="blue.500"
                    />
                    <Box pl={4}>
                      <Text color="#B4C1CB" fontWeight="600" fontSize="md">
                        {name}
                      </Text>
                      <Text color="#B4C1CB" fontSize="sm">
                        {occupation}
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </SliderItem>
            ))}
          </Slider>
        </Box>
      </Container>
    </Box>
  );
};

export default Testimonials;
