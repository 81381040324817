import React from 'react';
import Project from './project';
import { Box } from '@chakra-ui/react';

function RecentProjectContent() {
  const imageUrls = [
    'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-001.jpg',
    'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-002.jpg',
    'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-003.jpg',
    'https://dev-eyecast-media.s3.ap-southeast-2.amazonaws.com/project-004.jpg',
  ];

  return (
    <Box
      display={'grid'}
      gridTemplateColumns={[
        'repeat(auto-fill, minmax(100%, 1fr))',
        'repeat(auto-fill, minmax(30%, 1fr))',
        'repeat(auto-fill, minmax(30%, 1fr))',
        'repeat(auto-fill, minmax(30%, 1fr))',
        'repeat(auto-fill, minmax(15%, 1fr))',
      ]}
      gridGap={'1.5rem'}
    >
      {Array(4)
        .fill(undefined)
        .map((placeholder, index) => (
          <Project
            key={index}
            coverPhoto={imageUrls[index]}
            title={`Project 0${index}`}
            updatedAt={`2${index} July 2021`}
          />
        ))}
    </Box>
  );
}

export default RecentProjectContent;
