import React from 'react';

import OnboardingRedirect from './onboarding-redirect';
import PublicRoute from './public-route';

const OnboardingRoute = ({ ...props }) => {
  return (
    <OnboardingRedirect>
      <PublicRoute {...props} />
    </OnboardingRedirect>
  );
};

export default OnboardingRoute;
