const Button = {
  baseStyle: {
    border: 'none',
    _focus: {
      boxShadow: 'none',
    },
    _hover: {
      textDecoration: 'none',
    },
  },
  variants: {
    'ghost-primary': {
      color: '#9393A2',
      _hover: {
        bg: '#262630',
      },
    },
  },
};

export default Button;
