import React from 'react';
import { Flex, Box, Text, Switch } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useErrorHandler } from 'react-error-boundary';

import { Container, Header, HeaderTitle, Section } from 'layouts/shared';
import { Block, Button, LoadingView } from 'components';
import ErrorBoundary from 'components/error-fallback';
import useProfile from 'hooks/use-profile';
import useSaveProfile from 'hooks/use-save-profile';

const notifications = [
  {
    id: 'exportStatus',
    title: 'Export Status',
    text: 'Get notified when an export is done, stopped, paused or failed.',
  },
  {
    id: 'whatsNew',
    title: 'What’s New?',
    text: 'Stay up to date with the latest features and product launches in Clippy.',
  },
  {
    id: 'recommendations',
    title: 'Recommendations',
    text: 'Check out recommended templates and discover something new.',
  },
  {
    id: 'topTrendingTemplates',
    title: 'Top Trending Templates',
    text: 'Stay inspired and one step ahead with our top trending templates.',
  },
  {
    id: 'promotions',
    title: 'Promotions',
    text: 'Receive promotional offers.',
  },
];

const AccountNotifications = () => {
  const { data: profile, isLoading: isProfileLoading } = useProfile();
  const { mutateAsync, isLoading } = useSaveProfile();
  const handleError = useErrorHandler();

  const { register, handleSubmit } = useForm({
    defaultValues: profile?.notifications || {
      exportStatus: false,
      whatsNew: false,
      recommendations: false,
      topTrendingTemplates: false,
    },
  });

  const onSubmit = async (data) => {
    try {
      return await mutateAsync({
        data: { notifications: data },
        email: profile.email,
      });
    } catch (err) {
      handleError(err);
    }
  };

  if (isProfileLoading) return <LoadingView />;

  return (
    <ErrorBoundary>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Header>
            <HeaderTitle size={'lg'} fontWeight={700}>
              Notifications
            </HeaderTitle>
          </Header>

          <Section>
            <Block>
              <HeaderTitle
                as={'h2'}
                pb={10}
                size={'md'}
                fontWeight={700}
                color={'gray.500'}
              >
                The power is in your hands.
              </HeaderTitle>
              {notifications.map(({ id, title, text }) => (
                <Flex
                  key={id}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  _notLast={{
                    pb: 8,
                  }}
                >
                  <Box pr={4} color={'gray.500'}>
                    <HeaderTitle as={'h3'} pb={4} size={'sm'} fontWeight={700}>
                      {title}
                    </HeaderTitle>
                    <Text>{text}</Text>
                  </Box>
                  <Box>
                    <Switch
                      {...register(id)}
                      name={id}
                      defaultChecked={profile?.notifications?.[id]}
                      colorScheme={'purple'}
                      size={'lg'}
                    />
                  </Box>
                </Flex>
              ))}
            </Block>
            <Box textAlign={'right'}>
              <Button
                type={'submit'}
                label={'Save Changes'}
                colorScheme={'pink'}
                size="md"
                isLoading={isLoading}
                isDisabled={isLoading}
                loadingText={'Saving...'}
              />
            </Box>
          </Section>
        </form>
      </Container>
    </ErrorBoundary>
  );
};

export default AccountNotifications;
