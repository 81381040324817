import React from 'react';
import { Center, VStack } from '@chakra-ui/react';
import LoadingSpinner from './LoadingSpinner';
import spinnerAnimationData from '../../../assets/images/clippy-spinner.json';

export const LoadingView = () => {
  return (
    <VStack
      spacing={4}
      flex={1}
      bgColor={'shark'}
      height={'100vh'}
      align={'center'}
      justifyContent={'center'}
    >
      <Center w="100px" h="100px">
        <LoadingSpinner
          animationData={spinnerAnimationData}
          width={100}
          height={100}
        />
      </Center>
    </VStack>
  );
};
