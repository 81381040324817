import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button } from '@chakra-ui/react';

import HeaderUserMenu from 'layouts/shared/header/components/header-user-menu';
import Header from './header';
import { HOMEPAGE_NAV_ITEMS } from 'routes/nav-items';

const Layout = ({ children }) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    <LayoutWrapper>
      <LayoutHeader>
        <Header>
          {HOMEPAGE_NAV_ITEMS.map(({ id, name, path, variant }) => (
            <Button
              key={id}
              as={ReactLink}
              to={path}
              variant={variant}
              colorScheme="pink"
            >
              {name}
            </Button>
          ))}
          {isAuthenticated ? (
            <HeaderUserMenu />
          ) : (
            <>
              <Button
                variant="ghost-primary"
                onClick={() => loginWithRedirect()}
              >
                Log In
              </Button>
              <Button
                mt={[4, 0]}
                ml={[0, 4]}
                colorScheme="pink"
                onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
              >
                Sign Up
              </Button>
            </>
          )}
        </Header>
      </LayoutHeader>

      <LayoutMain>{children}</LayoutMain>
    </LayoutWrapper>
  );
};

const LayoutWrapper = ({ children }) => (
  <Box
    width={'100%'}
    minH={'100vh'}
    backgroundColor={'#1A1C22'}
    color={'white'}
  >
    {children}
  </Box>
);

const LayoutHeader = ({ children }) => (
  <Box
    width={'100%'}
    height="72px"
    position="fixed"
    top={0}
    zIndex={1}
    bg={'#17161D'}
  >
    {children}
  </Box>
);

const LayoutMain = ({ children }) => <Box mt="72px">{children}</Box>;

export default Layout;
