import React from 'react';
import Lottie from 'lottie-react';

export const TemplateLottie = ({ animData }) => {
  const lottieRef = React.useRef(null);

  const interactivity = {
    mode: 'cursor',
    actions: [
      {
        position: { x: [0, 1], y: [0, 1] },
        type: 'loop',
        frames: [45, 60],
      },
      {
        position: { x: -1, y: -1 },
        type: 'stop',
        frames: [45],
      },
    ],
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (lottieRef.current) {
        lottieRef.current.pause();
        console.log('running pause');
      }
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <Lottie
        lottieRef={lottieRef}
        animationData={animData}
        interactivity={interactivity}
      />
    </>
  );
};
