import React from 'react';
import { Heading, Flex, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { GradientText } from 'views/homepage/shared';

function ComingSoon() {
  return (
    <Flex
      pt={[8, null, null, 64]}
      direction="column"
      align="center"
      textAlign="center"
      sx={{
        '> *': {
          mb: 10,
        },
      }}
    >
      <Heading>
        <GradientText>Coming Soon...</GradientText>
      </Heading>
      <Link to="/dashboard">
        <Button colorScheme="pink" size="lg">
          Go to Dashboard
        </Button>
      </Link>
    </Flex>
  );
}

export default ComingSoon;
