import React from 'react';
import {
  Box,
  Heading,
  Text,
  AspectRatio,
  useBreakpointValue,
  Image,
} from '@chakra-ui/react';

export const Card = ({ children, ...rest }) => (
  <Box
    p={['0', '0.1rem', '0.5rem']}
    borderRadius={'0.25rem'}
    bgColor={'#262630'}
    {...rest}
  >
    {children}
  </Box>
);

export const CardContent = ({ children, ...rest }) => (
  <Box position={'relative'} {...rest}>
    {children}
  </Box>
);

export const CardPhoto = ({ src, title, ...rest }) => {
  // Define responsive values for aspect ratio
  const ratio = useBreakpointValue({ base: 16 / 9, md: 4 / 3, lg: 3 / 2 });
  return (
    <AspectRatio ratio={ratio} width="100%">
      <Image
        src={src}
        alt={title}
        objectFit="cover"
        borderRadius={'0.25rem'}
        {...rest}
      />
    </AspectRatio>
  );
};

export const CardFooter = ({ children }) => (
  <Box pt={2} px={1}>
    {children}
  </Box>
);

export const CardFooterTitle = ({ children, ...rest }) => (
  <Heading
    as="h1"
    size="md"
    isTruncated
    pb={'0.25rem'}
    fontWeight={700}
    {...rest}
  >
    {children}
  </Heading>
);

export const CardFooterTimestamp = ({ children, ...rest }) => (
  <Text as="span" fontSize="sm" fontWeight={300} {...rest}>
    {children}
  </Text>
);
