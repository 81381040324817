import { useMutation, useQueryClient } from 'react-query';
import useApi from './use-api';

export default function useTempalteFiles() {
  const queryClient = useQueryClient();
  const api = useApi();
  const uploadUrl =
    'https://9din4tc0m7.execute-api.ap-southeast-2.amazonaws.com/dev/media/pexel-to-s3-video';

  return useMutation(
    ({ fileUrl, pexelUrl, id }) =>
      api.post(uploadUrl, { fileUrl, pexelUrl, id }).then((res) => res.data),
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries('profile');
      },
    }
  );
}
