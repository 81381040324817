import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

import './payment-card-element.css';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#E2E8F0',
      fontFamily: 'Inter',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#E2E8F0',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const PaymentCardElement = () => {
  return <CardElement options={CARD_ELEMENT_OPTIONS} />;
};
export default PaymentCardElement;
