import React, { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import lottie from 'lottie-web';

const LoadingSpinner = ({ animationData, width, height }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    // Create a Lottie instance and load animation data
    const anim = lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg', // You can change the renderer as needed
      loop: true,
      autoplay: true,
      animationData: animationData, // Pass in the animationData prop
    });

    return () => {
      // Clean up the Lottie animation when the component unmounts
      anim.destroy();
    };
  }, [animationData]);

  return <Box ref={containerRef} width={width} height={height} />;
};

export default LoadingSpinner;
